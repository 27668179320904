import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
// import Popper from "@material-ui/core/Popper";

// import { TYPE_INFOS } from "./info.js"; //ConvTimeString,
import { ConvSpaceCodeString } from "variables/general.js"; //ConvTimeString,

import { bimWhiteColor } from "assets/jss/bim-webs.js";

const TYPE_INFOS = {
  // 可疑人员
  SuspiciousPerson: {
    title: "发现可疑人员",
    icon: "\ue92f",
    color: bimWhiteColor[0]
  },
  // 强行进入
  ForcedEntry: {
    title: "发现强行进入人员",
    icon: "\ue932",
    color: bimWhiteColor[0]
  },
  // 一键报警
  ButtonAlarm: {
    title: "发生一键报警",
    icon: "\ue934",
    color: bimWhiteColor[0]
  },
  // 入侵探测
  IntrusionDetection: {
    title: "入侵探测器报警",
    icon: "\ue933",
    color: bimWhiteColor[0]
  },
  // 门禁超时
  DoorTimeOut: {
    title: "门禁超时",
    icon: "\ue930",
    color: bimWhiteColor[0]
  },
  // 图像丢失
  ImageLost: {
    title: "图像丢失",
    icon: "\ue935",
    color: bimWhiteColor[0]
  },
  // 超长滞留
  LongStay: {
    icon: "\ue960",
    color: bimWhiteColor[0]
  },
  // 故障警告
  EquipFault: {
    title: "设备离线",
    icon: "\ue93b",
    color: bimWhiteColor[0]
  },
  // 火警
  FireAlarm: {
    title: "发生火灾预警",
    icon: "\ue939",
    color: bimWhiteColor[0]
  }
};

const styles = {
  snbar: {
    position: "absolute",
    top: "0.28rem",
    left: "0.28rem"
  },
  panel: {
    display: "inline-block",
    height: "2.48rem",
    width: "8.44rem"
  },
  close: {
    position: "absolute",
    display: "inline-block",
    fontSize: "0.2rem",
    lineHeight: "0.4rem",
    textAlign: "center",
    height: "0.4rem",
    width: "0.4rem"
  },
  info: {
    border: "0.01rem solid #515151",
    boxSizing: "border-box",
    background: "rgba(0,0,0,0.2)",
    position: "absolute",
    display: "inline-block",
    top: 0,
    left: 0,
    height: "2.48rem",
    width: "2.48rem"
  },
  title: {
    display: "block",
    width: "100%",
    color: bimWhiteColor[0],
    fontSize: "0.2rem",
    lineHeight: "0.4rem",
    textAlign: "center"
  },
  pos: {
    display: "block",
    width: "100%",
    color: bimWhiteColor[0],
    fontSize: "0.16rem",
    lineHeight: "0.4rem",
    textAlign: "center"
  }
};

class TipsBarComponent extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    const { classes, data, onClose } = this.props;

    let info = data ? TYPE_INFOS[data.type] : null;
    let postr = data ? ConvSpaceCodeString(data.pos) : "";
    if (data && data.iscar) {
      info.title = "车辆滞留" + data.duration;
      postr = data.mid;
    }
    // console.log("infobar page render", data);
    return (
      <div className={classes.snbar}>
        {data && (
          <div>
            <div className={classes.info}>
              <span
                className={"icon font_family"}
                style={{
                  display: "block",
                  paddingTop: "0.32rem",
                  paddingBottom: "0.2rem",
                  width: "100%",
                  color: bimWhiteColor[0],
                  fontSize: "0.96rem",
                  lineHeight: "0.96rem",
                  textAlign: "center"
                }}
              >
                {info.icon}
              </span>
              <div className={classes.title}>{info.title}</div>
              <div className={classes.pos}>{"位置 : " + postr}</div>
            </div>
            <div className={classes.close} onClick={onClose}>
              X
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(TipsBarComponent);
export { TYPE_INFOS };
