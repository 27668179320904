import { xFetch, xFetchRaw, filtersGetPath } from "util/webfetch";

export const getFilters = module => {
  const path = filtersGetPath(module);
  return xFetch(path);
};

export const getKeywordsearch = (module, path, body) => {
  console.log("-----getKeywordsearch", module, body);
  // const path = KeywordSearch(module);
  return xFetchRaw(path, {
    params: { body: JSON.stringify(body) }
  });
};

const filter = {
  getFilters,
  getKeywordsearch
};
export default filter;
