import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import clone from "lodash/clone";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from '@material-ui/core/ListItemIcon';

import { connect } from "react-redux";
import { checkPermKey } from "contexts/AuthContext";

// import isEqual from "lodash/isEqual";
import {
  bimGrayColor,
  bimYellowColor,
  SNV_BTN_HEIGHT,
  bimWhiteColor,
  bimBlackColor
} from "assets/jss/bim-webs.js";

// const NAV_BTN_WIDTH = 1.24;

const styles = {
  list: {
    // width: NAV_BTN_WIDTH + "rem",
    padding: 0,
    reversed: true
  },
  item: {
    boxSizing: "border-box",
    borderTop: "solid .01rem " + bimGrayColor[1],
    height: SNV_BTN_HEIGHT,
    fontSize: "0.2rem",
    lineHeight: SNV_BTN_HEIGHT,
    background: bimBlackColor[0],
    color: bimWhiteColor[0]
  },
  selcolor: {
    background: bimYellowColor[0],
    color: bimBlackColor[0]
  },
  selbtn: {
    "&:hover": {
      backgroundColor: "none",
      color: "none"
    }
  },
  nonbtn: {
    "&:hover": {
      backgroundColor: "none",
      color: bimYellowColor[0]
    }
  },
  locationBox: {
    borderLeft: "solid .01rem " + bimGrayColor[1],
    background: bimBlackColor[0],
    // marginLeft: "0.2rem"
    position: "absolute",
    right: "0",
    display: "flex",
    justifyContent: "center",
    width: "0.5rem",
    minWidth: "unset"
  },
  locationIcon: {
    fontSize: "0.3rem",
    color: bimWhiteColor[0],
    // width: "100%"
    // marginLeft: "0.2rem"
  },
  iconSelect: {
    color: bimYellowColor[0],
  },
  itemDisabled: {
    boxSizing: "border-box",
    borderTop: "solid .01rem " + bimGrayColor[1],
    height: SNV_BTN_HEIGHT,
    fontSize: "0.2rem",
    lineHeight: SNV_BTN_HEIGHT,
    background: "#222",
    color: bimGrayColor[0]
  }
};

class NavigeComponent extends Component {
  static defaultProps = {
    items: null, //["AAA", "BBB", "CCC"],
    selected: 0
  };

  constructor(props) {
    super(props)
    this.state = { authPerm: null, fromGoPreBtn: false };
  }

  componentDidMount() {
    this.setState({fromGoPreBtn: false})
    if(!this.state.authPerm){
      //设置权限
      let keys = Object.keys(this.props.authChildren)
      if(keys.length > 0) {
        let authPerm = this.props.authChildren[keys[0]]
        this.setState({authPerm})
      }
    }
  }

  componentDidUpdate(prevProps) {
    if(!this.state.authPerm || (prevProps.authChildren !== this.props.authChildren)){
      //设置权限
      let keys = Object.keys(this.props.authChildren)
      if(keys.length > 0) {
        let authPerm = this.props.authChildren[keys[0]]
        this.setState({authPerm})
      }
    }

  }

  handleClickItem = idx => {
    // console.log("handleClickItem", idx);
    if (this.props.onSelect) {
      this.props.onSelect(idx);
    }
  };

  render() {
    const { classes, items, selected, needLocation, handleLocation } = this.props;
    let total = items ? items.length - 1 : 0;
    let its = items ? clone(items).reverse() : [];

    return (
      <List className={classes.list}>
        {items &&
          its.map((item, idx) => {
            let sidx = total - idx;
            if(checkPermKey(this.state.authPerm, item.uq_key)) {
              return (
                <div key={idx}>
                  <ListItem
                    button
                    key={idx}
                    style={{paddingRight: needLocation ? "0.7rem" : "16px"}}
                    className={classNames({
                      [classes.item]: true,
                      [classes.selcolor]: selected === sidx
                    })}
                    classes={{
                      button: selected === sidx ? classes.selbtn : classes.nonbtn
                    }}
                    onClick={() => this.handleClickItem(sidx)}
                  >
                    <ListItemText primary={item.name} disableTypography={true} />
                    {needLocation && <ListItemIcon className={classes.locationBox}>
                      <i
                        className={classNames("icon", "font_family", classes.locationIcon, {[classes.iconSelect]: selected === sidx})}
                        onClick={(e) => handleLocation(sidx, e)}
                        >&#xe937;</i>
                    </ListItemIcon>}
                  </ListItem>
                </div>
              );
            } else {
              return (
                <div key={idx}>
                  <ListItem
                    key={idx}
                    style={{paddingRight: needLocation ? "0.7rem" : "16px"}}
                    className={classNames({
                      [classes.itemDisabled]: true
                    })}
                  >
                    <ListItemText primary={item.name} disableTypography={true} />
                    {needLocation && <ListItemIcon className={classes.locationBox}>
                      <i
                        className={classNames("icon", "font_family", classes.locationIcon, {[classes.iconSelect]: selected === sidx})}
                        onClick={(e) => handleLocation(sidx, e)}
                        >&#xe937;</i>
                    </ListItemIcon>}
                  </ListItem>
                </div>
              );
            }
          })}
      </List>
    );
  }
}

export default withStyles(styles)(
  connect(
    ({ auth: { authChildren } }) => {
      return {
        authChildren
      };
    }
  )(NavigeComponent)
);
