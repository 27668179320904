import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import fill from "lodash/fill";
// import isEqual from "lodash/isEqual";

// import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import {
  // SNV_STOREY_BOTTOM,
  SNV_BTN_HEIGHT,
  bimWhiteColor,
  bimYellowColor
} from "assets/jss/bim-webs.js";

//
const styles = {
  snbar: {
    zIndex: 2,
    position: "absolute",
    top: "-0.92rem", //`calc(100% - ${SNV_STOREY_BOTTOM})`,
    left: 0
  },
  button: {
    position: "absolute",
    // fontWeight: "600",
    textAlign: "center",
    left: 0,
    width: "1.2rem",
    height: SNV_BTN_HEIGHT,
    fontSize: "0.2rem",
    lineHeight: SNV_BTN_HEIGHT,
    background: "rgba(0,0,0,0.1)",
    color: bimWhiteColor[0],
    fontFamily: "SourceHanSansCN-Normal",
    cursor: "pointer"
  },
  floorBtnBox: {
    width: "1.2rem",
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "column-reverse"
  },
  floorBtn: {
    textAlign: "center",
    width: "1.2rem",
    minHeight: SNV_BTN_HEIGHT,
    fontSize: "0.2rem",
    background: "rgba(0,0,0,0.1)",
    color: bimWhiteColor[0],
    fontFamily: "SourceHanSansCN-Normal",
    cursor: "pointer",
    wordBreak: "break-all",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "0.25rem"

  },
  selcolor: {
    color: bimYellowColor[0],
    fontWeight: "bold",
    fontSize: "0.25rem",
    fontFamily: "SourceHanSansCN-Bold"
  },
  disable: {
    color: "#AAA"
  }
};

const STOREY_ITEM_MAX = 10;

class StoreyBarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { from: 0 };
  }

  componentDidMount() {
    //console.log("MainComponent componentDidMount", this.props);
    //GetSpaceTree()

    //加载SourceHanSansCN-Bold字体，解决第一次进来选中效果延迟问题
    this.span = document.createElement("span");
    this.span.style.visibility = "hidden";
    this.span.style.fontFamily = "SourceHanSansCN-Bold";
    document.body.appendChild(this.span);
  }

  componentWillUnmount() {
    document.body.removeChild(this.span);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.storeys !== this.props.storeys) {
      //console.log("MainComponent componentDidUpdate", this.props);
      this.setState({ from: 0 });
    }
    // if (!isEqual(prevProps.storeys, this.props.storeys)) {
    //   if (this.props.storeys) {
    //     this.setState({ from: 0 });
    //   }
    // }
  }

  handleItemClick = idx => {
    // console.log("handleItemClick");
    const { onSelect, storeys } = this.props;
    if (onSelect && storeys) {
      onSelect(idx);
    }
  };
  handlePrev = () => {
    const { storeys } = this.props;
    if (storeys && this.state.from > 0) {
      let from = this.state.from - STOREY_ITEM_MAX;
      this.setState({ from });
    }
  };
  handleNext = () => {
    const { storeys } = this.props;
    if (storeys && this.state.from + STOREY_ITEM_MAX < storeys.length) {
      let from = this.state.from + STOREY_ITEM_MAX;
      this.setState({ from });
    }
  };
  handleAll = () => {
    const { onSelect, storeys } = this.props;
    if (onSelect && storeys) {
      onSelect(-1);
    }
  };

  render() {
    const { classes, storeys, open, select } = this.props; //buildname,
    const { from } = this.state;

    let prev = from > 0;
    let next = false;
    let count = 0;
    if (storeys) {
      count = Math.min(storeys.length - from, STOREY_ITEM_MAX);
      next = from + count < storeys.length;
    }

    // console.log("StoreyBarComponent", count);

    return (
      <div className={classes.snbar}>
        <Paper
          id="list-grow"
          style={{ visibility: storeys && open ? "visible" : "hidden" }}
        >
          
          {/* <div
            className={classNames({
              [classes.button]: true,
              [classes.selcolor]: select < 0
            })}
            style={{ bottom: "0.48rem" }}
            onClick={this.handleAll}
          >
            {"整座楼"}
          </div> */}
          <div className={classes.floorBtnBox}>
            <div
              className={classNames({
                [classes.floorBtn]: true,
                [classes.disable]: !prev
              })}
              style={{ bottom: 0 }}
              onClick={() => {
                if (prev) {
                  this.handlePrev();
                }
              }}
            >
              <ExpandMore />
            </div>
            {count > 0 &&
              fill(Array(count), null).map((_, index) => {
                let sid = index + from;
                let item = storeys[sid];
                return (
                  <div
                    key={index}
                    className={classNames({
                      [classes.floorBtn]: true,
                      [classes.selcolor]: select === sid
                    })}
                    // style={{ bottom: 0.48 * (index + 1) + "rem" }}
                    onClick={() => this.handleItemClick(sid)}
                  >
                    {item.name}
                  </div>
                );
              })}
            <div
              className={classNames({
                [classes.floorBtn]: true,
                [classes.disable]: !next
              })}
              style={{ bottom: 0.48 * (count + 1) + "rem" }}
              onClick={() => {
                if (next) {
                  this.handleNext();
                }
              }}
            >
              <ExpandLess />
            </div>
          </div>
          
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(StoreyBarComponent);
