import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import toast from "actions/toast";

const toastState = handleActions(
  {
    [toast.toastVisible.set]: (state, action) => {
      const { data } = action.payload;
      return data;
    }
  },
  { show: false }
);

export default combineReducers({
  toastState
});
