import React from "react";
import PropTypes from "prop-types";

// material-ui components
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { bimYellowColor, bimBlackColor, bimWhiteColor } from "assets/jss/bim-webs.js";

const styles = () => ({
  root: {
    borderRadius: 0,
    fontSize: "0.20rem",
    lineHeight: "0.24rem",
    height: "0.48rem",
    width: "1.24rem",
    minWidth: "0.64rem",
    padding: 0
  },
  containedPrimary: {
    backgroundColor: bimBlackColor[1],
    color: bimWhiteColor[0],
    "&:hover": {
      backgroundColor: bimBlackColor[6]
    },
    "&:active": {
      backgroundColor: bimBlackColor[7]
    },
    "&.Mui-disabled": {
      color: "#232323",
      background: "#A8A699"
    }
  },
  containedSecondary: {
    backgroundColor: bimYellowColor[0],
    color: bimBlackColor[1],
    "&:hover": {
      backgroundColor: bimYellowColor[1]
    },
    "&:active": {
      backgroundColor: bimYellowColor[2]
    },
    "&.Mui-disabled": {
      color: "#232323",
      background: "#A8A699"
    }
  },
  textPrimary: {
    color: bimBlackColor[1]
  },
  textSecondary: {
    color: bimYellowColor[0]
  },
  sizeSmall: {
    fontSize: "0.17rem",
    lineHeight: "0.36rem",
    height: "0.36rem",
    width: "0.72rem"
  }
});

// const useStyles = makeStyles(styles);

function RegularButton(props) {
  // const classes = useStyles();
  const { variant = "contained", classes, ...rest } = props;

  //console.log('btnClasses is',btnClasses);
  return <Button variant={variant} elevation={0} classes={classes} {...rest} />;
}

RegularButton.propTypes = {
  classes: PropTypes.object,
  variant: PropTypes.string
};

export default withStyles(styles)(RegularButton);
