import React from "react";
import { withStyles } from "@material-ui/core/styles";

export default withStyles({ root: {} })(function Icon({
  name,
  style,
  className,
  classes
}) {
  const clsName = `icon font_family ${className} ${classes.root}`;
  return (
    <span className={clsName} style={{ ...style, cursor: "pointer" }}>
      {name}
    </span>
  );
});
