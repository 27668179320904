import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

import {
  bimYellowColor,
  bimGrayColor
} from "assets/jss/bim-webs.js";

const styles = {
  root: {
    width: "0.6rem",
    height: "0.38rem",
    padding: "0.12rem"
  },
  switchBase: {
    color: "#00a6ff",
    width: "0.2rem",
    height: "0.2rem",
    padding: "0.09rem",
    '& + $track': {
      backgroundColor: "#00a6ff",
    },
    '&$checked': {
      color: bimYellowColor[0],
    },
    '&$checked + $track': {
      backgroundColor: bimYellowColor[0],
    },
    '&$disabled': {
      color: bimGrayColor[0],
    },
    '&$disabled + $track': {
      backgroundColor: bimGrayColor[0],
    },
    
  },
  disabled: {},
  checked: {},
  track: {},
  thumb: {
    width: "0.2rem",
    height: "0.2rem",
  }
}

class SwitchComponent extends Component {

  render() {
    const { classes, switchOn, disabled, icon, checkedIcon, onChange} = this.props;

    if (icon && checkedIcon) {
      return (
        <Switch
          checked={switchOn}
          onChange={onChange}
          disabled={disabled}
          icon={icon()}
          checkedIcon={checkedIcon()}
          // color=""
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            track: classes.track,
            checked: classes.checked,
            disabled: classes.disabled,
            thumb: classes.thumb
          }}
        />
      );
    }
    return (
      <Switch
        checked={switchOn}
        onChange={onChange}
        disabled={disabled}
        // color=""
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          track: classes.track,
          checked: classes.checked,
          disabled: classes.disabled,
          thumb: classes.thumb
        }}
      />
    );
  }
}

export default withStyles(styles)(SwitchComponent);