import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import actions from "actions/auth";
import { IsAuth } from "variables/general"

const AuthContext = React.createContext({
  checkPerm: () => false
});

//需要从第一级传需要查询的uq_key
function checkPermFunc(childrenList, first, ...rest) {
  // console.log(">>>>>>checkPerm", childrenList, first, rest);
  if(!IsAuth()) return true;

  if (!first) {
    return true;
  }
  if (!childrenList || !childrenList.length) {
    return false;
  }
  let finded = false;
  for (let i = 0; i < childrenList.length; i++) {
    const toCheck = childrenList[i];
    // console.log(">>>>>> check:", toCheck.uri, first, toCheck.uri === first);
    if (toCheck.uq_key === first) {
      finded = toCheck;
      break;
    }
  }

  if (!finded) {
    return false;
  }

  // console.log("finded: >>>>>>>>");
  return checkPermFunc(finded.children, ...rest);
}

//只需要传查询的uq_key
export function checkPermKey(tree, key) {
  if(!IsAuth()) return true;

  if(!key || !tree) return true;

  let stark = [];
  stark = stark.concat(tree);

  while(stark.length) {
    let temp = stark.shift();
    if(temp.children) {
        stark = temp.children.concat(stark);
    }
    if(key === temp.uq_key) {
        return true;
    }
  }
}

class AuthProviderClass extends Component {
  static propTypes = {
    rule: PropTypes.string,
    authChildren: PropTypes.array,
    children: PropTypes.node,
    getAuthChildren: PropTypes.func
  };
  // constructor(props) {
  //   super(props);
  //   this.state = { authChildren: [] };
  // }

  componentDidMount() {
    const { getAuthChildren, rule } = this.props;
    getAuthChildren(rule);
    // getAuthChildren().then(authChildren => {
    //   this.setState({ authChildren });
    // });
  }

  render() {
    const { authChildren, children } = this.props;
    const checkPerm = (...keys) => checkPermFunc(authChildren, ...keys);
    return (
      <AuthContext.Provider value={checkPerm}>{children}</AuthContext.Provider>
    );
  }
}

export const AuthProvider = connect(
  ({ auth: { authChildren } }, { rule }) => {
    // console.log("=======auth: ", authChildren);
    return {
      authChildren: authChildren[rule] || []
    };
  },
  dispatch => ({
    getAuthChildren: rule => dispatch(actions.getAuthChildren.do(rule))
  })
)(AuthProviderClass);

export default AuthContext;
