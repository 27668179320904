import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

import CheckBoxIcon from "@material-ui/icons/CheckBoxOutlined";

import { bimYellowColor } from "assets/jss/bim-webs.js";

export default withStyles({
  colorPrimary: {
    color: "white"
  },
  checked: {
    color: bimYellowColor[0]
  }
})(({ classes, ...props }) => (
  <Checkbox
    classes={{ ...classes }}
    color="primary"
    checkedIcon={<CheckBoxIcon className={classes.checked} />}
    {...props}
  />
));
