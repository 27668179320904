import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import auth from "actions/auth";

const authChildren = handleActions(
  {
    [auth.getAuthChildren.end]: (state, action) => {
      if (action.error) {
        return state;
      }
      const { rule } = action.meta;
      const { payload } = action;

      return {
        [rule]: [...payload]
      };
    }
  },
  []
);

const userInfo = handleActions(
  {
    [auth.userInfo.set]: (state, action) => {
      const { data } = action.payload;
      return data;
    }
  },
  { data: {} }
);

export default combineReducers({
  authChildren,
  userInfo
});
