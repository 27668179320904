import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Scrollbars } from "react-custom-scrollbars";
import NavigeBar from "components/SpaceNaver/NavigeBar";
import CustomCamera from "components/CustomCamera/CustomCamera";
import Compass from "components/CustomCompass/CustomCompass";

import {
  // fetchCameraRtsp
  fecthSecurityKeyAreas
} from "util/webfetch.js";

import {
  LogicModuleName,
  UeS_enterMode,
  UeS_navigateSpace,
  UeS_spaceColor,
  UeS_spaceSelect,
  CBN_ASKSHOWTIP,
  UeToU_setCallback,
  UeS_filterEquip,
  UeS_tool_trans,
  CBN_ASKCLOSECAMERA
} from "variables/uescene";

import {
  GetSpaceTree,
  WaitEnterScene,
  ColorToUE4Rgba,
  SearchSpaceParent
} from "variables/general";

import {
  bimGrayColor,
  bimWhiteColor,
  bimYellowColor
} from "assets/jss/bim-webs.js";

// import WebSocks from "util/websocks";

const styles = {
  root: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  menusel: {
    borderBottom: "1px solid " + bimGrayColor[1],
    boxSizing: "border-box",
    background: "rgba(136,136,136,0.8)",
    width: "2.0rem",
    height: "0.48rem",
    fontSize: "0.16rem",
    color: "#888",
    lineHeight: "0.48rem",
    paddingLeft: "0.12rem"
  },
  menuitem: {
    borderBottom: "1px solid " + bimGrayColor[1],
    boxSizing: "border-box",
    background: "rgba(36,36,36,0.8)",
    width: "2.0rem",
    height: "0.48rem",
    fontSize: "0.16rem",
    color: bimWhiteColor[0],
    lineHeight: "0.48rem",
    paddingLeft: "0.12rem"
    // textAlign: "center"
  },
  leftscene: {
    // background: "rgba(160,16,16,0.6)",
    position: "absolute",
    display: "inlineBlock",
    height: "100%",
    width: `calc(100% - 6.58rem)`
  },
  right: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "6.58rem",
    height: "100%",
    overflow: "auto"
  },
  rmain: {
    boxShadow: "0px 3px 18px 0px rgba(0, 0, 0, 0.24)",
    border: "solid 2px " + bimYellowColor[0],
    //boxSizing: "border-box",
    // background: bimBlackColor[2],
    //padding: "0.02rem 0.02rem",
    position: "absolute",
    width: "6.58rem",
    height: "3.56rem",
    top: "0.02rem",
    right: "0.02rem"
  },
  ritem: {
    // background: bimBlackColor[2],
    padding: "0.02rem 0.02rem",
    display: "inline-block",
    position: "absolute",
    width: "3.26rem",
    height: "1.88rem"
  },
  ritsel: {
    boxShadow: "0px 3px 18px 0px rgba(0, 0, 0, 0.24)",
    border: "solid 2px " + bimYellowColor[0],
    // background: bimBlackColor[2],
    // padding: "0.02rem 0.02rem",
    display: "inline-block",
    position: "absolute",
    width: "3.26rem",
    height: "1.88rem"
  },
  video: {
    opacity: 0.8,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  },
  snbar: {
    position: "absolute",
    bottom: 0
    // left: "0.24rem"
  },
  leftCamera: {
    border: "0.01rem solid #515151",
    boxSizing: "border-box",
    background: "rgba(0,0,0,0.2)",
    position: "absolute",
    display: "inline-block",
    top: "0.28rem",
    left: "0.28rem",
    height: "2.48rem",
    width: "4.4rem"
  }
};

const show_alarm_icons = {
  CCTV: {
    ImageLost: {
      type: "video_alarm"
    },
    SuspiciousPerson: {
      type: "scs_suspiciouspersons"
    }
  },
  CR: {
    DoorTimeOut: {
      type: "accesscontrol_alarm"
    }
  },
  PIC: {
    PerimeterAlarm: {
      type: "scs_Perimeteralarm"
    },
    ForceDentry: {
      type: "scs_forcedentry"
    }
  },
  DODM: {
    OneTouchAlarm: {
      type: "scs_onetouchalarm"
    }
  },
  PIID: {
    IntrusionDetection: {
      type: "scs_intrusiondetection"
    }
  }
};
const show_icons = {
  CCTV: {
    type: "video",
    Isblock: false
  },
  CR: {
    type: "accesscontrol",
    Isblock: false
  },
  PIC: {
    type: "scs_Perimeter",
    Isblock: false
  }
};
class PageComponent extends Component {
  constructor(props) {
    super(props);

    this.isShowFloor = false;
    this.storeyEqps = {};
    // fetchCameraRtsp(
    //   JSON.stringify({
    //     cctvs: cameras
    //   }),
    //   this.handleCameraRtsps
    // );

    fecthSecurityKeyAreas('', this.handleAreasAndEqps);

    // this.camaddrs = null;
    this.state = {
      selected: 0,
      selcam: 0,
      isShowRight: true,
      allCameras: [],
      allMenuData: [],
      sceneseCamera: null
    };
    this.modulename = null;
    UeS_enterMode(LogicModuleName.Scs_key);
    // WebSocks.subscribe();
  }

  componentDidMount() {
    // console.log("render- componentDidMount");
    // WaitEnterScene(LogicModuleName.Scs_key, this.startpage);
  }

  // componentDidUpdate(prevProps, prevState) {
  // }

  // componentWillUnmount() {
  //   console.log("Area page componentWillUnmount");
  //   UeS_delVideoPlay({
  //     video: ["main", "c0", "c1", "c2", "c3", "c4", "c5"]
  //   });
  // }

  setUesSpace() {
    let usrgba = ColorToUE4Rgba("#e2cc4b");
    let space_ids = [];
    let space_colors = [];
    let space_uis = [];

    const {allMenuData} = this.state;

    allMenuData.forEach(item => {
      space_ids.push(item.mid);
      space_colors.push(usrgba);
      space_uis.push(
        JSON.stringify({
          type: "text",
          title: item.title
        })
      );
    });
    UeS_spaceColor({
      space_ids,
      space_colors,
      space_uis
    });
  }

  setSelectSpace(idx) {
    let usrgba = ColorToUE4Rgba("#e2cc4b");
    UeS_spaceSelect({
      space_mid: this.state.allMenuData[idx].mid,
      color: usrgba
    });
  }

  startpage = name => {
    this.modulename = name;

    UeToU_setCallback(CBN_ASKSHOWTIP, this.handleAskShowtip);
    UeToU_setCallback(CBN_ASKCLOSECAMERA, this.handleAskCloseCamera);
    let st = GetSpaceTree();
    if (st) {
      let park = st[0];
      UeS_navigateSpace({ park_mid: park.mid }, { Style: "UnNormal" });
    }

    this.setUesSpace();
    this.setSelectSpace(0);

    // if (this.camaddrs) {
    //   this.forceUpdate();
    // }
  };
  handleAskShowtip = param => {
    const {allMenuData} = this.state;
    let index = -1;
    let sceneseCamera = null;

    allMenuData.forEach((data, idx) => {
      if (data.mid === param.mid) {
        index = idx;
      }
      data.showEqps && data.showEqps.forEach(eqp => {
        if (eqp.code === "CCTV" && eqp.mid === param.mid) {
          console.log('handleAskShowtip', param, eqp, data);
          sceneseCamera = {
            type: eqp.alarm_type,
            status: eqp.status,
            mid: param.mid
          };
        }
      });
    });
    if (index > -1) {
      this.setState({ selected: index, selcam: index });
    }
    this.setState({sceneseCamera});
  };
  handleAskCloseCamera = param => {
    this.setState({ sceneseCamera: null });
  }

  // handleCameraRtsps = msg => {
  //   if (msg && msg.data) {
  //     this.camaddrs = this.cameras.map(mid => {
  //       return { mid, addr: msg.data[mid] };
  //     });
  //     console.log("handleCameraRtsps", this.camaddrs, msg.data);
  //     if (this.modulename) {
  //       this.forceUpdate();
  //     }
  //   }
  // };
  handleAreasAndEqps = msg => {
    if (msg && msg.data) {
      let arr = [];
      msg.data.forEach(item => {
        arr = arr.concat(item.cameras);

        this.handleEquipList(item);
      });
      console.log('handleAreasAndEqps', msg.data);
      this.setState({allCameras: arr, allMenuData: msg.data});
      WaitEnterScene(LogicModuleName.Scs_key, this.startpage);
    }
  }

  handleEquipList = (item) => {
    item.eqpsObj = {
      equip_ids: [],
      equip_states: [],
      equip_uis: []
    };
    // const {storey_mid} = item.locationSpaceInfo;
    item.showEqps.forEach(eqp => {
      item.eqpsObj.equip_ids.push(eqp.mid);
      item.eqpsObj.equip_states.push(eqp.status);
      if (eqp.alarm_type !== "") {
        item.eqpsObj.equip_uis.push(!show_alarm_icons[eqp.code][eqp.alarm_type]
            ? ""
            : JSON.stringify({title: eqp.title || '', ...show_alarm_icons[eqp.code][eqp.alarm_type]}));
      } else {
        item.eqpsObj.equip_uis.push(!show_icons[eqp.code]
          ? "" 
          :  JSON.stringify({title: eqp.title || '', ...show_icons[eqp.code]}));
      }
    });
  }

  handleCameraSelect = idx => {
    console.log("handleCameraSelect", idx);
    let sel = idx === this.state.allMenuData.length ? idx - 1 : idx;
    if (this.state.selected !== sel) {
      this.setState({ selected: sel, selcam: idx });
      this.setSelectSpace(sel);
    } else {
      if (this.state.selcam !== idx) {
        this.setState({ selcam: idx });
      }
    }
  };

  handleNavigateSelect = idx => {
    // console.log("handleNavigateSelect", idx);
    if (this.state.selected === idx && !this.isShowFloor) {
      return;
    } else {
      if (this.isShowFloor) {
        this.isShowFloor = false;
        this.setState({isShowRight: true, sceneseCamera: null});
        WaitEnterScene(LogicModuleName.Scs_key, () => {
          UeToU_setCallback(CBN_ASKSHOWTIP, this.handleAskShowtip);
          let st = GetSpaceTree();
          if (st) {
            let park = st[0];
            UeS_navigateSpace({ park_mid: park.mid }, { Style: "UnNormal" });
          }
          this.setUesSpace();
          this.setSelectSpace(idx);
        })
        UeS_filterEquip({
          equip_ids: [],
          equip_states: [],
          equip_uis: []
        });
      } else {
        this.setState({ selected: idx, selcam: idx, sceneseCamera: null });
        this.setSelectSpace(idx);
      }
    }
  };

  handleLocation = (ind, e) => {
    this.isShowFloor = true;
    e.stopPropagation();
    const item = this.state.allMenuData[ind];
    this.setState({isShowRight: false});

    let ret = SearchSpaceParent(item.mid);
    UeS_navigateSpace(ret); // 显示楼层
    UeS_spaceColor({
      space_ids: []
    });
    this.setState({ selected: ind }); // 选中导航
    this.setSelectSpace(ind); // 选中房间的呼吸效果
    // const {storey_mid} = item.locationSpaceInfo;
    UeS_filterEquip({
      ...this.state.allMenuData[ind].eqpsObj,
      ...item.locationSpaceInfo
    });
    UeS_tool_trans({
      IsTrans: "false",
      Value: ""
    });

  };
  render() {
    const { classes } = this.props;
    const { selected, selcam, isShowRight, allCameras, allMenuData, sceneseCamera } = this.state;

    return (
      <div className={classes.root}>
        <div ref={this.refScene} className={classes.leftscene} style={{width: isShowRight ? 'calc(100% - 6.58rem)': '100%'}}>
          <Compass />
          <div className={classes.snbar}>
            <NavigeBar
              items={allMenuData}
              selected={selected}
              onSelect={this.handleNavigateSelect}
              needLocation={true}
              handleLocation={this.handleLocation}
            />
          </div>
        </div>
        {isShowRight && allCameras && <div className={classes.right}>
          <Scrollbars
            autoHide
            style={{ width: "100%", height: "100%" }}
            renderThumbVertical={props => (
              <div
                {...props}
                style={{ width: "0.08rem", background: "#fff" }}
              />
            )}
          >
          {allCameras && allCameras.length>0 && <div className={classes.rmain}>
            <CustomCamera
              name={"sa_main"}
              device_mid={allCameras[selcam]}
              // addr={this.camaddrs[selcam].addr}
              isBut={"3"}
            />
          </div>}
          { allCameras.map((mid, idx) => {
            let x = idx & 1 ? "3.34rem" : "0.02rem";
            let y = Math.floor(idx / 2) * 1.94 + 3.64;
            return (
              <div
                // ref={refcam}
                key={idx}
                className={idx === selcam ? classes.ritsel : classes.ritem}
                style={{ right: x, top: y + "rem" }}
                onClick={() => this.handleCameraSelect(idx)}
              >
                <CustomCamera name={"sa_c" + idx} device_mid={mid} />
              </div>
            );
          })}
          </Scrollbars>
        </div>}
        {!isShowRight && sceneseCamera && <div className={classes.leftCamera}>
          <CustomCamera
            name={"left_sa_main"}
            device_mid={sceneseCamera.mid}
            // addr={this.camaddrs[selcam].addr}
            isBut={"1"}
          />
        </div>}
      </div>
    );
  }
}

export default withStyles(styles)(PageComponent);

// "rtsp://admin:yanfeng123@172.18.137.37:554/h264/ch1/sub/av_stream"
/* <Player> "sa_c" + i;
              <source src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4" />
            </Player> */
/* <video
              className={classes.video}
              preload="auto"
              autoPlay={true}
              loop={true}
            >
              <source src="https://img.txqn.huohua.cn/material/201971217617783b7131-8ba5-402e-bb83-7b2160142d89.mp4" />
            </video> */
