import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import isEmpty from "lodash/isEmpty";
import { format as formatDate } from "date-fns";

import fltr$ from "actions/filters";

const FIELD_TYPE_LIKE = "like";
const FIELD_TYPE_TREE = "tree";
const FIELD_TYPE_TREE_RADIO = "radio";
const FIELD_TYPE_TIMERAGE = "timerange";
const FIELD_TYPE_DATETIME = "datetime";

const inputs = handleActions(
  {
    [fltr$.getFilters.end]: (state, action) => {
      if (action.error) {
        return state;
      }
      const { payload, meta } = action;
      const { module } = meta;

      let inputs = [];
      for (let i = 0, l = payload.length; i < l; ++i) {
        let item = payload[i];
        if (!item) {
          continue;
        }
        // TODO: other field_type
        if (FIELD_TYPE_LIKE === item.field_type) {
          inputs = [...inputs, { ...item, value: "" }];
        }

        if (FIELD_TYPE_TIMERAGE === item.field_type) {
          inputs = [...inputs, { ...item, value: {} }];
        }

        if (FIELD_TYPE_DATETIME === item.field_type) {
          inputs = [...inputs, { ...item, value: null }];
        }
      }

      return {
        ...state,
        [module]: inputs
      };
    },

    [fltr$.filterInput.change]: (state, action) => {
      if (action.error) {
        return state;
      }

      const { payload, meta } = action;
      const { field, value } = payload;
      const { module } = meta;

      const oldInputs = state[module];

      return {
        ...state,
        [module]: oldInputs.map(x => {
          if (x.field === field) {
            return {
              ...x,
              value
            };
          }
          return x;
        })
      };
    }
  },
  {}
);

const genFilterTree = data => {
  console.log("genFilterTree", data);
  const root = {
    field: data.field,
    id: data.field,
    name: data.name,
    root: data.name,
    knm: data.name,
    expand: true, //默认第一级展开
    check: 0,
    path: [data.field],
    children: [],
    field_type: data.field_type
  };

  const treeMap = { 0: root };

  let menu = data.menu;
  if (menu && menu.length) {
    for (let k = 0; k < 3; ++k) {
      let leftMenus = [];
      for (let i = 0, l = menu.length; i < l; ++i) {
        const menuItem = menu[i];
        const parentId = menuItem.parent_id || 0;
        const parent = treeMap[parentId];
        if (parent) {
          const adit = {
            ...menuItem,
            path: [...parent.path, menuItem.id],
            expand: false,
            check: 0,
            children: []
          };
          parent.children = [...parent.children, adit];
          if (undefined !== menuItem.parent_id) {
            treeMap[menuItem.id] = adit;
          }
        } else {
          leftMenus.push(menuItem);
        }
      }
      if (leftMenus.length > 0) {
        menu = leftMenus;
      } else {
        break;
      }
    }
  }

  return root;
};

function shadowReplace(tree, path, gen) {
  if (!path.length) {
    return tree || [];
  }
  const [first, ...rest] = path;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.id === first) {
      const shadowedChildren = shadowReplace(node.children, rest, gen);

      const genNode = gen({ ...node, children: shadowedChildren });
      return [...tree.slice(0, i), genNode, ...tree.slice(i + 1)];
    }
  }

  return tree;
}

function replaceAllDescendants(tree, gen) {
  if (!tree || !tree.length) {
    return [];
  }

  return tree.map(x => {
    return {
      ...gen(x),
      children: replaceAllDescendants(x.children, gen)
    };
  });
}

// check:
// 0: 无
// 1: checked
// -1: 半check
const tree = handleActions(
  {
    [fltr$.getFilters.end]: (state, action) => {
      if (action.error) {
        return state;
      }
      const { payload, meta } = action;

      const { module } = meta;
      const tree = [];
      for (let i = 0, l = payload.length; i < l; ++i) {
        let item = payload[i];
        if (!item) {
          continue;
        }
        if (FIELD_TYPE_TREE === item.field_type || FIELD_TYPE_TREE_RADIO === item.field_type) {
          tree.push(genFilterTree(item));
        }
      }

      return {
        ...state,
        [module]: {
          current: tree
        }
      };
    },
    [fltr$.filterTree.expand]: (state, action) => {
      if (action.error) {
        return state;
      }

      const { payload, meta } = action;
      const { path, id, expand } = payload;
      const { module } = meta;

      const oldState = state[module];
      const newTree = shadowReplace(oldState.current, path, node => {
        if (node.id === id) {
          return {
            ...node,
            expand
          };
        }
        return node;
      });

      return {
        ...state,
        [module]: {
          backup: oldState.backup || oldState.current,
          current: newTree
        }
      };
    },
    [fltr$.filterTree.check]: (state, action) => {
      if (action.error) {
        return state;
      }

      const { payload, meta } = action;
      const { path, id, check } = payload;
      const { module } = meta;

      const oldState = state[module];
      const newTree = shadowReplace(oldState.current, path, node => {
        // 本身
        if (node.id === id) {
          return {
            ...node,
            check,
            // 子孙都设为一样的
            children: replaceAllDescendants(node.children, child => {
              return {
                ...child,
                check
              };
            })
          };
        }

        // 祖先
        for (let i = 0; i < node.children.length; i++) {
          if (node.children[i].check !== check) {
            return {
              ...node,
              check: CHECK_TYPE_SEMI
            };
          }
        }
        return {
          ...node,
          check
        };
      });

      return {
        ...state,
        [module]: {
          backup: oldState.backup || oldState.current,
          current: newTree
        }
      };
    },

    [fltr$.filterTree.rollback]: (state, action) => {
      const { meta } = action;
      const { module } = meta;
      const oldState = state[module];
      return {
        ...state,
        [module]: {
          current: oldState.backup || oldState.current
        }
      };
    },
    [fltr$.filterTree.commit]: (state, action) => {
      // table 改变过后，就不再能回退
      const { meta } = action;
      const { module } = meta;
      const oldState = state[module];
      if (!oldState) {
        return state;
      }
      return {
        ...state,
        [module]: {
          current: oldState.current
        }
      };
    }
  },
  {}
);

const keywords = handleActions(
  {
    [fltr$.getKeywordsearch.end]: (state, action) => {
      if (action.error) {
        return state;
      }
      const { payload, meta } = action;
      const { module } = meta;
      const { field, list } = payload;

      console.log("getKeywordsearch.end", payload);

      // return payload.keywords ? payload.keywords.map((v)=>v.name) : [];
      const old = state[module] || {};
      return {
        ...state,
        [module]: {
          ...old,
          [field]: list
        }
      };
    }
  },
  {}
);

export default combineReducers({
  inputs,
  tree,
  keywords
});

export const CHECK_TYPE_NO = 0;
export const CHECK_TYPE_YES = 1;
export const CHECK_TYPE_SEMI = 2;

// helpers:
function getLeaves(root, map) {
  if (!root.children || !root.children.length) {
    // 这就是叶子节点了
    // console.log("----------leave:", root);
    if (map(root)) {
      return [root.id];
    }
    return [];
  }

  return root.children.reduce((all, it) => {
    return [...all, ...getLeaves(it, map)];
  }, []);
}

// const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss";
const DATE_TIME_FORMAT = "yyyy-MM-dd";

export const composeFilters = ({ inputs, tree }) => {
  const inputsResult = {};
  inputs.forEach(x => {
    if (x.value && x.field_type === FIELD_TYPE_LIKE) {
      inputsResult[x.field] = x.value;
    }
    if (x.field_type === FIELD_TYPE_TIMERAGE && !isEmpty(x.value)) {
      const { start, end } = x.value;
      // 防止两个空值
      if (start || end) {
        // 默认值
        const startTime = start
          ? formatDate(start, DATE_TIME_FORMAT)
          : "0000-00-00 00:00:00";
        const endTime = end
          ? formatDate(end, DATE_TIME_FORMAT)
          : "9999-12-31 24:00:00";
        inputsResult[x.field] = `${startTime}~${endTime}`;
      }
    }
    if (x.field_type === FIELD_TYPE_DATETIME && x.value) {
      inputsResult[x.field] = formatDate(x.value, DATE_TIME_FORMAT);
    }
  });

  const trees = tree
    .map(root => {
      let values = getLeaves(root, node => node.check === CHECK_TYPE_YES);

      // console.log(">>>>>>>tree", root.field, " ==leaves: ", values);
      if (FIELD_TYPE_TREE_RADIO === root.field_type) {
        values = values.join(",");
      }
      return {
        key: [root.field],
        values: values
      };
    })
    .filter(x => x.values && x.values.length)
    .reduce((all, it) => {
      return {
        ...all,
        [it.key]: it.values
      };
    }, {});

  return {
    ...inputsResult,
    ...trees
  };
};
