import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import navsp from "actions/navspace";

const spaceTreeState = handleActions(
  {
    [navsp.spaceTree.set]: (state, action) => {
      const { tree } = action.payload;
      return { spaceTree: tree };
    }
  },
  { spaceTree: null }
);

const parkTreeState = handleActions(
  {
    [navsp.parkTree.set]: (state, action) => {
      const { parktree } = action.payload;
      return { parkTree: parktree };
    }
  },
  { parkTree: null }
);

const navSpaceState = handleActions(
  {
    [navsp.navigationSpace.set]: (state, action) => {
      const { mids } = action.payload;
      return { curSpaceMids: mids };
    }
  },
  { curSpaceMids: null }
);

const storeySpaces = handleActions(
  {
    [navsp.getSpacesByStorey.end]: (state, action) => {
      if (action.error) {
        return state;
      }

      const { payload, meta } = action;
      const { storeyMid } = meta;
      return {
        ...state,
        [storeyMid]: payload
      };
    }
  },
  {}
);

export default combineReducers({
  spaceTreeState,
  navSpaceState,
  storeySpaces,
  parkTreeState
});
