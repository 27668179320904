import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import loading from "actions/loading";

const loadingState = handleActions(
  {
    [loading.loadingFrame.set]: (state, action) => {
      const { open } = action.payload;
      return { open };
    }
  },
  { open: false }
);

export default combineReducers({
  loadingState
});
