/*!

=========================================================
* bim web - v1.8.0
=========================================================

*/
import React from "react";
import ReactDOM from "react-dom";

import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { Provider } from "react-redux";

import createSagaMiddleware from "redux-saga";

import reducers from "./reducers";
import sagas from "./sagas";

// core components
import { UeS_init } from "variables/uescene";
import { InitUIScene, GetParkMid } from "variables/general";
import RoutePage from "routes";
import { HashRouter, Switch } from "react-router-dom";
import "assets/css/bim-webs.css?v=0.0.2";

// console.log("------------------------------------------------");
// console.log(process);
//console.log("------------------------------------------------");
//console.log(process.env.REACT_APP_ENV);
// console.log(process.argv);
//console.log("------------------------------------------------");

UeS_init(GetParkMid()); //process.env.REACT_APP_ENV || "504"
InitUIScene();

/*
 * config redux and sagas
 */
const initialState = {};
const sagaMiddleware = createSagaMiddleware();
const enhancer = composeWithDevTools(
  applyMiddleware(sagaMiddleware)
  /* others */
);
const store = createStore(reducers, initialState, enhancer);
window.store = store;
sagas.start(sagaMiddleware);

/*
 * render
 */
function render() {
  ReactDOM.render(
    <HashRouter>
        <Switch>
          <Provider store={store}>
            <RoutePage />
          </Provider>
        </Switch>
    </HashRouter>,
    document.getElementById("root")
  );
}

render();

/*
 * 开发环境下代码热更新
 */
if (process.env.NODE_ENV !== "production") {
  if (module.hot) {
    module.hot.accept("./reducers/index", () => {
      store.replaceReducer(reducers);
    });

    module.hot.accept("./sagas/index", () => {
      sagas.cancel(store);
      sagas.start(sagaMiddleware);
    });

    module.hot.accept(["./routes"], () => {
      render();
    });
  }
}

/*
210/504/PEK
颜色根据各工程修改
const bimBlackColor = ["#111111"=5, "#232323"=7, "rgba(17,17,17,0.8)"];
const bimWhiteColor = ["#FFF"=44, "#DEDEDE", "#EAEAEA"=6, "#BABABA"];

CustomSelect.js custSelectStyle.css
backgroundColor: "#FFFFFF00",
*/
