import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Scrollbars } from "react-custom-scrollbars";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { AS_PxTorem } from "variables/general.js";

import {
  bimBlackColor,
  bimWhiteColor,
  bimYellowColor
} from "assets/jss/bim-webs.js";

const styles = {
  arecord: {
    width: "3.28rem",
    height: "3.68rem",
    lineHeight: ".24rem",
    fontSize: ".14rem",
    padding: ".28rem",
    boxSizing: "border-box",
    border: "rgb(234, 234, 234) .01rem solid",
    background: bimBlackColor[3],
    color: bimWhiteColor[0],
    position: "absolute"
  },
  ahead: {
    color: bimYellowColor[0],
    position: "relative",
    marginBottom: ".16rem"
  },
  atitle: { fontSize: ".18rem" },
  download: {
    fontSize: ".2rem",
    color: bimYellowColor[0],
    position: "absolute",
    right: "0",
    cursor: "pointer"
  },
  aitem: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: ".2rem"
  },
  adate: { flex: "1" },
  aname: { width: "1rem" },
  astate: { width: ".28rem" },
  alist: {
    height: "2.56rem",
    overflow: "hidden",
    marginRight: "-.2rem"
  }
};

class AccessRecordComponent extends Component {
  // constructor(props) {
  //   super(props);
  //   // console.log("门禁参数", props);
  // }

  render() {
    const { classes, dx, dy, recordData, recordPos, onClose } = this.props; //, curSpace

    let title = "24小时门禁记录";
    let pos = recordPos;
    let arecord = recordData ? recordData : null;

    let x = AS_PxTorem(dx) + "rem";
    let y = AS_PxTorem(dy) + "rem";

    return (
      <ClickAwayListener
        mouseEvent={"onClick"}
        touchEvent={false}
        onClickAway={() => {
          if (onClose) {
            onClose();
          }
        }}
      >
        <div
          className={classes.arecord}
          style={{ position: "fixed", top: y, left: x }}
        >
          <div className={classes.ahead}>
            <span
              className={"icon font_family " + classes.download}
              style={{ fontSize: ".24rem" }}
            >
              {"\ue949"}
            </span>
            <div className={classes.atitle}>{title}</div>
            <div className={classes.aposition}>{pos}</div>
          </div>
          <div className={classes.alist}>
            <Scrollbars
              autoHide
              style={{ width: "100%", height: "2.6rem" }}
              renderThumbVertical={props => (
                <div
                  {...props}
                  style={{ width: "0.08rem", background: "#747474" }}
                />
              )}
            >
              {arecord &&
                arecord.list &&
                arecord.list.map((item, idx) => {
                  return (
                    <div key={idx} className={classes.aitem}>
                      <div className={classes.adate}>{item.accessTime}</div>
                      <div className={classes.aname}>{item.content}</div>
                      <div className={classes.astate}>
                        {item.operation_name}
                      </div>
                    </div>
                  );
                })}
            </Scrollbars>
          </div>
        </div>
      </ClickAwayListener>
    );
  }
}

export default withStyles(styles)(AccessRecordComponent);
