import { createActions } from "redux-actions";
import { defaultCreator } from "./defaults";

export default createActions({
  GET_FILTERS: {
    DO: defaultCreator,
    END: defaultCreator
  },
  FILTER_INPUT: {
    CHANGE: defaultCreator
  },
  FILTER_TREE: {
    EXPAND: defaultCreator,
    CHECK: defaultCreator,
    ROLLBACK: defaultCreator,
    COMMIT: defaultCreator
  },
  GET_KEYWORDSEARCH: {
    DO: defaultCreator,
    END: defaultCreator
  }
});
