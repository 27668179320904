/*!

 =========================================================
 * 
 =========================================================

  */

const bimTheme = {
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      root: {
        fontFamily: "SourceHanSansCN-Normal,Roboto-Light"
      }
    },
    MuiDialog: {
      root: {
        fontSize: "0.16rem"
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: "0.16rem",
        color: "#000000",
        marginBottom: "0.06rem"
      }
    },

    MuiTableCell: {
      root: {
        padding: "0 0.1rem",
        fontSize: "0.16rem",
        lineHeight: "0.28rem",
        height: "0.32rem",
        fontFamily: "SourceHanSansCN-Normal,Roboto-Light",
        borderBottom: 0
      },
      head: {
        fontSize: "0.16rem",
        lineHeight: "0.28rem",
        height: "0.32rem"
      }
      // stickyHeader:{
      // },
      // stickyHeader: true
    },
    // 可以在需要的地方使用fontSize，这里统一定义width,height会使得具体使用处的fontSize失效
    MuiSvgIcon: {
      root: {
        // width: "0.24rem",
        // height: "0.24rem",
        fontSize: "0.32rem"
      }
    },
    MuiToolbar: {
      regular: {
        minHeight: "0.36rem"
      }
    },

    MuiTypography: {
      body1: {
        fontSize: "0.14rem",
        fontFamily: "SourceHanSansCN-Normal,Roboto-Light"
      },
      body2: {
        fontSize: "0.16rem",
        fontFamily: "SourceHanSansCN-Normal,Roboto-Light",
        width: "auto",
        lineHeight: "0.36rem"
        // letter-spacing: 0.01071em;
      },
      caption: {
        color: "#f00"
      }
    },

    MuiMenuItem: {
      root: {
        fontSize: "0.2rem",
        fontFamily: "SourceHanSansCN-Normal,Roboto-Light"
      }
    },

    MuiTablePagination: {
      root: {
        fontSize: "0.16rem",
        lineHeight: "0.32rem",
        maxWidth: "4.8rem",
        margin: "0 auto"
        // color: "#fff"
      },
      toolbar: {
        minHeight: "inherit",
        height: "0.36rem",
        padding: "0 .24rem",
        justifyContent: "space-between"
      },
      selectRoot: {
        margin: "0 !IMPORTANT",
        height: "0.36rem",
        lineHeight: "0.36rem"
      },
      select: {
        paddingTop: "0",
        paddingBottom: "0",
        paddingRight: ".32rem"
      },
      spacer: {
        display: "none"
      },
      selectIcon: {
        color: "inherit",
        right: 0,
        width: ".32rem",
        height: ".32rem"
      }
      // selectRoot: {
      //   // `.selectRoot` should be merged with `.input` in v5.
      //   marginRight: 0,
      //   marginLeft: 0
      // },
      // /* Styles applied to the Select component `select` class. */
      // select: {
      //   // paddingLeft: 8,
      //   // paddingRight: 24,
      //   textAlign: "center",
      //   textAlignLast: "center" // Align <select> on Chrome.
      // }
    },

    MuiPaper: {
      rounded: {
        borderRadius: 0
      }
      // elevation1: {
      //   boxShadow: 0
      // }
    },

    MuiTooltip: {
      tooltip: {
        fontSize: ".18rem",
        fontFamily: "SourceHanSansCN-Normal,Roboto-Light",
        backgroundColor: "#e2cc4b",
        color: "#424242",
        lineHeight: ".2rem",
        padding: ".14rem",
        borderRadius: "0",
        maxWidth: "2rem"
      }
    },
    //   MuiPaper-elevation1 {
    //     box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    // }

    MuiBadge: {
      badge: {
        fontSize: "0.16rem"
      }
    },

    MuiCircularProgress: {
      colorPrimary: {
        color: "#A09C9C"
      },
      colorSecondary: {
        color: "#E2CC4B"
      }
    },

    MuiFilledInput: {
      input: {
        padding: 0
      }
    },
    MuiInputBase: {
      root: {
        fontFamily: "SourceHanSansCN-Normal,Roboto-Light"
      },
      input: {
        padding: 0
      }
    },
    MuiFormHelperText: {
      root: {
        fontFamily: "SourceHanSansCN-Normal,Roboto-Light"
      },
    },
    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: "none" // "rgba(128, 128, 128, 0.5)"
        }
      }
    },
    MuiTab: {
      root: {
        fontFamily: "SourceHanSansCN-Normal,Roboto-Light"
      },
    },
    MuiFab: {
      root: {
        "&:hover": {
          backgroundColor: "#C5AD20"
        }
      }
    }
  }
};

export default bimTheme;