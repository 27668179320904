///////////////////////////////////////////////////////////////////////
// 与ue场景间交互 from UI to UE Scene
// eslint-disable-next-line no-undef
UE4MouseEvent.onmousedown = function (ev) {
  // console.log("UE4MouseEvent.onmousedown iiiiiiiiii", ev);
  var target = document.getElementById("root");
  var e = document.createEvent("MouseEvents");
  e.initMouseEvent(
    "click",
    true,
    true,
    window,
    1,
    2,
    3,
    2,
    3,
    false,
    false,
    true,
    false,
    0,
    target
  );
  target.dispatchEvent(e);
};

// 调用UE4Func
function ExcuteObjectFunctionWithJson(_funclist) {
  // console.log("ExcuteObjectFunctionWithJson", JSON.stringify(_funclist));
  // eslint-disable-next-line no-undef
  triggerUE4EventJSON(
    "onJavascriptEventJSON",
    JSON.stringify({
      UE4Func: _funclist
    })
  );
}

///////////////////////////////////////////////////////////////////////
function UeS_close() {
  ExcuteObjectFunctionWithJson([
    {
      funcname: "GameOver",
      json: ""
    }
  ]);
}
function UeS_mini() {
  // console.log("UeS_mini");
  ExcuteObjectFunctionWithJson([
    {
      funcname: "WindowMiniSize",
      json: ""
    }
  ]);
}

///////////////////////////////////////////////////////////////////////
// 绑定HTML移动事件
// 实现自定义鼠标切换

// function bindHtmlMouseEvent() {
//   UE4MouseEvent.onmousemove = function (event) {
//     console.log(1);
//     //$('body').css("cursor", "url(assets/img/icon_sketup_on.png) 64 64 ,pointer");
// };
// UE4MouseEvent.onmouseleave = function (event) {
//   console.log(2);
//   // $('body').css("cursor", "");
// };
// }
///////////////////////////////////////////////////////////////////////
/* 场景进入模块
{
  // 模块名称
  mode_name : "space","equipment",
    // 场景操作完的回调
  callback：console.log
}
*/
const LogicModuleName = {
  Main: "main", // 主页
  Scs_almc: "scs_almc", // 安防报警中心
  Scs_key: "scs_key", // 安防重点区域
};

var Current_Mode_Name = null;
var Current_Mode_State = null;

function UeS_enterMode(name) {
  // console.log("UeS_enterMode");
  let param =
    "ues_callback('" +
    JSON.stringify({ option: "enterMode", param: name }) +
    "')";
  console.log("UeS_enterMode", `{mode_name: ${name},callback: ${param}}`);
  if (!(window.ue && window.ue.uecom)) {
    Current_Mode_Name = name;
    return;
  }

  UeS_filterEquip(null);
  UeS_spaceColor(null);
  UeS_pakingFilter(null);

  ExcuteObjectFunctionWithJson([
    {
      funcname: "Currency_ModeSwitching",
      json: JSON.stringify({
        mode_name: name,
        callback: param
      })
    }
  ]);
  console.log("UeS_enterMode---Currency_ModeSwitching", `{mode_name: ${name},callback: ${param}}`);
  // Current_Mode_Name = name;
}

/* 场景导航到空间位置
{
  // 园区
  park_mid : ,
  // 楼座
  building_mid :,
  // 楼层
  storey_mid : ,
  // 场景操作完的回调
  callback
}
*/
// var last_space = null;
function UeS_navigateSpace(space, state) {
  // console.log("UeS_navigateSpace", space);
  if (!Current_Mode_Name) {
    // last_space = space;
    return;
  }
  // last_space = null;
  let lst = [
    {
      funcname: "Currency_NavigateSpace",
      json: JSON.stringify(space)
    }
  ];
  if (!state) {
    state = Current_Mode_State;
  } else {
    Current_Mode_State = state;
  }
  if (state) {
    lst.push({
      funcname: "Space_State",
      json: JSON.stringify(state)
    });
  }
  console.log("UeS_navigateSpace---", lst);
  ExcuteObjectFunctionWithJson(lst);
  // ExcuteObjectFunctionWithJson([
  //   {
  //     funcname: "Currency_NavigateSpace",
  //     json: JSON.stringify(space)
  //   },
  // ]);
}

/* 在场景内显示装备选中效果
{
  // 园区
  park_mid : ,
  // 楼座
  building_mid :,
  // 楼层
  storey_mid : ,
  // 设备
  equipment_mid:,
}
*/
function UeS_selectEquip(space) {
  if (!Current_Mode_Name) {
    return;
  }
  console.log("UeS_selectEquip---Equip_Select", space);
  ExcuteObjectFunctionWithJson([
    {
      funcname: "Equip_Select",
      json: JSON.stringify(space)
    }
  ]);
}

/* 在场景内过滤装备显示
{
  // 园区
  park_mid : ,
  // 楼座
  building_mid :,
  // 楼层
  storey_mid : ,
  // 
  fault_equip_ids:[],
  equip_types:[],
  pipeline_types:[],
  equip_uis:[
  JSON.stringify({
    "type": "dashboard",
    "title": "一氧化碳",
    "value": 50,
    "thresholdvalue":80
}),
  ]
}
*/
function UeS_filterEquip(json) {
  if (!Current_Mode_Name) {
    return;
  }
  console.log("UeS_filterEquip---Equip_filter", JSON.stringify(json));

  ExcuteObjectFunctionWithJson([
    {
      funcname: "Equip_filter",
      json: json ? JSON.stringify(json) : null
    }
  ]);
}

function UeS_filterEquipGroup(json) {
  if (!Current_Mode_Name) {
    return;
  }
  console.log("Public page componentWillUnmount UeS_filterEquipGroup go", JSON.stringify(json));
  ExcuteObjectFunctionWithJson([
    {
      funcname: "EquipGroup_filter",
      json: json ? JSON.stringify(json) : null
    }
  ]);
}

function Ues_SelectEquipGroup(json) {
  if (!Current_Mode_Name) {
    return;
  }
  console.log("Ues_SelectEquipGroup go", json);
  ExcuteObjectFunctionWithJson([
    {
      funcname: "EquipGroup_Select",
      json: JSON.stringify(json)
    }
  ]);
}

function Ues_ChangeEquipGroup(json) {
  console.log("Ues_ChangeEquipGroup go", json);
  ExcuteObjectFunctionWithJson([
    {
      funcname: "EquipGroup_Change",
      json: JSON.stringify(json)
    }
  ]);
}

//场景模型切换状态
/*
var _json = JSON.stringify({
    "Style": 'Normal',//
});
*/
function UeS_modelState(space) {
  if (!Current_Mode_Name) {
    return;
  }
  console.log("UeS_modelState---Space_State", JSON.stringify(space));
  Current_Mode_State = space;
  ExcuteObjectFunctionWithJson([
    {
      funcname: "Space_State",
      json: JSON.stringify(space)
    }
  ]);
}

//空间颜色
/*
var _json = JSON.stringify({
    "space_ids": [],
    "space_colors": [],
    "space_uis": [],
});
*/
function UeS_spaceColor(json) {
  if (!Current_Mode_Name) {
    return;
  }
  console.log("UeS_spaceColor---Space_filter", JSON.stringify(json));
  ExcuteObjectFunctionWithJson([
    {
      funcname: "Space_filter",
      json: json ? JSON.stringify(json) : null
    }
  ]);
}

//选中空间
/*
var _json = JSON.stringify({
    //"park_mid": 'BJ_NLSL62',
    //"building_mid": 'BJ_NLSL62_B',
    "space_mid": 'BJ_NLSL62_B_SA.F11',
    "color": '',
});
*/
function UeS_spaceSelect(space) {
  if (!Current_Mode_Name) {
    return;
  }
  console.log("UeS_spaceSelect go", JSON.stringify(space));
  ExcuteObjectFunctionWithJson([
    {
      funcname: "Space_Select",
      json: JSON.stringify(space)
    }
  ]);
}

/* 在场景内过滤资产显示
{
  asset_ids:[],
}
*/
function UeS_filterAsset(json) {
  if (!Current_Mode_Name) {
    return;
  }
  console.log("UeS_filterAsset go", JSON.stringify(json));
  ExcuteObjectFunctionWithJson([
    {
      funcname: "Asset_filter",
      json: json ? JSON.stringify(json) : null
    }
  ]);
}
/* 在场景内显示资产选中效果
{
  // 设备
  asset_mid:
}
*/
function UeS_selectAsset(space) {
  if (!Current_Mode_Name) {
    return;
  }
  console.log("UeS_selectAsset go", space);
  ExcuteObjectFunctionWithJson([
    {
      funcname: "Asset_Select",
      json: JSON.stringify(space)
    }
  ]);
}

/*添加视频
    var _json = JSON.stringify({
        "video": [
            {
                "name": "1001",
                "title": "测试1",
                "url": "rtsp://admin:biad1234@172.18.53.137:554/h264/ch1/main/av_stream",
                "x": 100,
                "y": 100,
                "w": 480,
                "h": 270
            },
            {
                "name": "1002",
                "title": "测试2",
                "url": "rtsp://admin:biad1234@172.18.53.137:554/h264/ch1/main/av_stream",
                "x": 500,
                "y": 500,
                "w": 480,
                "h": 270
            }
        ],
    });
*/

function UeS_addVideoPlay(space, target) {
  if (!Current_Mode_Name) {
    return;
  }

  console.log("UeS_addVideoPlay go", JSON.stringify(space));

  if (AdVideo != null && AdVideo.isHTML) {
    space.video.forEach(function (_space) {
      _space.target = target;
      AdVideo.add(_space);
    });
  } else {
    ExcuteObjectFunctionWithJson([
      {
        funcname: "Video_Add",
        json: JSON.stringify(space)
      }
    ]);
  }
}

/*修改视频
    var _json = JSON.stringify({
        "video": [
            {
                "name": "1001",
                "title": "测试3",
                "url": "rtsp://admin:biad1234@172.18.53.137:554/h264/ch1/main/av_stream1",
            },
            {
                "name": "1002",
                "title": "测试4",
                "url": "rtsp://admin:biad1234@172.18.53.137:554/h264/ch1/main/av_stream",
            }
        ],
    });
 */
function UeS_modifyVideoPlay(space, target) {
  if (!Current_Mode_Name) {
    return;
  }
  console.log("UeS_modifyVideoPlay go", JSON.stringify(space));

  if (AdVideo != null && AdVideo.isHTML) {
    space.video.forEach(function (_space) {
      _space.target = target;
      AdVideo.modefiy(_space);
    });
  } else {
    ExcuteObjectFunctionWithJson([
      {
        funcname: "Video_Modify",
        json: JSON.stringify(space)
      }
    ]);
  }
}

/*关闭视频
    var _json = JSON.stringify({
        "video": [
            "1001",
            "1002"
        ],
    });
 */
function UeS_delVideoPlay(space) {
  if (!Current_Mode_Name) {
    return;
  }
  console.log("UeS_delVideoPlay go", JSON.stringify(space));

  if (AdVideo != null && AdVideo.isHTML) {
    space.video.forEach(function (_space) {
      AdVideo.destroy(_space);
    });
  } else {
    ExcuteObjectFunctionWithJson([
      {
        funcname: "Video_Delete",
        json: JSON.stringify(space)
      }
    ]);
  }
}

var AdVideo = new function() {
  this.isHTML = false;

  this.map = new Map();

  // var ws_ip = '172.16.50.42';
  // var ws_port = '8888';

  // var _json = {
  //   url: "",
  //   target: "parentNode"//video
  // }
  //"rtsp://admin:iotree123@192.168.3.5:554/mpeg4/ch1/sub/av_stream",
  //<live-player video-url="ws://172.18.51.11:10800/ws-flv/hls/stream_1.flv" live="true" stretch="true"></live-player>

  this.add = function (params) {
    //测试
    // params.url = "ws://172.18.51.11:10800/ws-flv/hls/stream_"+(parseInt(10*Math.random())+1).toString()+".flv";
    // params.url = "http://localhost:8888/live/get?url="+params.url;
    params.url = "ws://localhost:8888/live/ws?url="+params.url+".flv";
    // params.url = `rtmp://localhost:1013/rtmp/0`

    this.map.set(params.name, params);
    console.log(this.map)
    params.target.style.cssText = "position: relative;z-index: 0;";
    let Parent = document.createElement('div');
    Parent.className = 'Ad-video-Parent';
    Parent.style.cssText = 'width:100%;height:100%;background-color: #000000;position: absolute;top: 0;left: 0;z-index: -1;';
    params.target.appendChild(Parent);

    let video = document.createElement('live-player');
    let W = params.target.clientWidth;
    let H = params.target.clientHeight;
    let width=0,height=0;
    if((W/16)<=(H/9)){
      width=W;
      height=(width/16)*9;

    }else{
      width=(H/9)*16;
      height=H;
    }
    video.style.cssText = 'width:'+width+'px;height:'+height+'px;position:absolute;top:0px;left:0px;bottom:0px;right:0px;margin:auto;';
    video.live = true;
    video.stretch = true;
    video.controls = false;
    video.videoUrl = params.url;
    Parent.appendChild(video);

    video.getVueInstance().play();
    // var flvPlayer = window.flvjs.createPlayer({
    //   type: "flv",
    //   isLive: true,
    //   url: `ws://${ws_ip}:${ws_port}/rtsp/0/?url=${params.url}`,
    //   // url: `ws://192.168.3.72:8888/rtsp/${id}/?url=${params.url}`,
    // });
    // flvPlayer.attachMediaElement(video);
    // flvPlayer.load();
    // flvPlayer.play();

    // video.flvPlayer = flvPlayer;

    // console.log(video.parentNode);
  }
  this.modefiy = function (params) {
    let submap = this.map.get(params.name);
    let Parent = submap.target.querySelector('.Ad-video-Parent');
    submap.target = params.target;
    if (params.url !== null&&params.url !== submap.url) {
      submap.url = params.url;
      Parent = null;
      this.destroy(params.name);
      this.add(submap);
    }else{
      submap.target.appendChild(Parent);
    }
    this.destroy(params.name);
    this.add(submap);
  }
  this.destroy = function (params) {
    let submap = this.map.get(params);

    if (submap.target !== null) {
      let video = submap.target.querySelector("live-player");
      if (video !== null) {
        video.getVueInstance().pause();
        submap.target.removeChild(video.parentNode);
      }
    }
    this.map.delete(params.name);
  }
  return this;
}();

// 摄像头-截图
// {
//   "name":"videoID",
//   "FileName":"保存图片的名称+后缀名",
//   "shotWidth":"图片的宽",
//   "shotWidth":"图片的高",
//   "Error":"报错的function"
//   }
function Ues_SaveScreenshot(params) {
  console.log("Ues_SaveScreenshot go", JSON.stringify(params));
  ExcuteObjectFunctionWithJson([
    {
      funcname: "Video_SaveScreenshot",
      json: params ? JSON.stringify(params) : null
    }
  ])
  // Video_SaveScreenshot(params);
}

// 停车
/*
        var _json = JSON.stringify({
            "parking_ids": ["B1-092", "B1-093", "B1-094"],
            "parking_states": ["0true", "1false", "1true"],
            "parking_uis": ["{\"type\":\"scs_forcedentry\"}", "{\"type\":\"meeting_free\",\"title\":\"测试ui2\"}", "{\"type\":\"text_1\",\"title\":\"加长加长加长测试ui3\"}"]

        });
*/
function UeS_pakingFilter(json) {
  if (!Current_Mode_Name) {
    return;
  }
  console.log("UeS_pakingFilter go", JSON.stringify(json));
  ExcuteObjectFunctionWithJson([
    {
      funcname: "Parking_filter",
      json: json ? JSON.stringify(json) : null
    }
  ]);
}
// 停车
/*
var _json = JSON.stringify({
            "parking_mid": 'B1-094',//Normal OutRoom  InRoom
            "parking_state":"1false"
        });*/
function UeS_pakingSelect(json) {
  if (!Current_Mode_Name) {
    return;
  }
  console.log("UeS_pakingSelect go", JSON.stringify(json));
  ExcuteObjectFunctionWithJson([
    {
      funcname: "Parking_Select",
      json: JSON.stringify(json)
    }
  ]);
}

////////////////////////////////////////////////////////////
/*添加视频 
    var _json = JSON.stringify({
            "isVisible": true,
            "x": 0,
            "y": 0,
            "w": 480,
            "h": 270
});
*/
function UeS_showMiniMap(json) {
  if (!Current_Mode_Name) {
    return;
  }
  console.log("UeS_showMiniMap go", JSON.stringify(json));
  ExcuteObjectFunctionWithJson([
    {
      funcname: "MiniMap_Visible",
      json: JSON.stringify(json)
    }
  ]);
}
/*设置指南针的回调 
    var _json = JSON.stringify({
            "isVisible": true,
});
*/
function UeS_enableCompass(enable) {
  if (!Current_Mode_Name) {
    return;
  }
  console.log("UeS_enableCompass---Compass_Visible", `{isVisible: ${enable}}`);
  ExcuteObjectFunctionWithJson([
    {
      funcname: "Compass_Visible",
      json: JSON.stringify({
        isVisible: enable ? true : false
      })
    }
  ]);
}
/*重置指南针
 */
function UeS_resetCompass() {
  if (!Current_Mode_Name) {
    return;
  }
  console.log("UeS_resetCompass go");
  ExcuteObjectFunctionWithJson([
    {
      funcname: "Compass_Click",
      json: ""
    }
  ]);
}

////////////////////////////////////////////////////////////
// 是否显示轴网
/*
        var _json = JSON.stringify({
            "bShowAxis": 'true',
        });
*/
function UeS_tool_showaxis(json) {
  if (!Current_Mode_Name) {
    return;
  }
  if (!(window.ue && window.ue.uecom)) {
    if (UeToU_Callbacks[CBN_ASKTOOLBAR]) {
      UeToU_Callbacks[CBN_ASKTOOLBAR]({
        idx: -1,
        isOpen: json.bShowAxis === "true"
      });
    }
    return;
  }
  console.log("UeS_tool_showaxis go", JSON.stringify(json));
  ExcuteObjectFunctionWithJson([
    {
      funcname: "ShowOrHideAxis",
      json: JSON.stringify(json)
    }
  ]);
}
// 改变透明度
/*
IsTrans:是否透明  Value:透明值（float ：0~1）    
        var _json = JSON.stringify({
            "IsTrans": 'true',
            "Value": '0.5',
        });
*/
function UeS_tool_trans(json) {
  if (!Current_Mode_Name) {
    return;
  }
  if (UeToU_Callbacks[CBN_ASKTOOLBAR]) {
    UeToU_Callbacks[CBN_ASKTOOLBAR]({
      idx: 0,
      isOpen: json.IsTrans === "true"
    });
  }
  if (!(window.ue && window.ue.uecom)) {
    return;
  }

  console.log("UeS_tool_trans go", JSON.stringify(json));
  ExcuteObjectFunctionWithJson([
    {
      funcname: "ChangeComponentTrans",
      json: JSON.stringify(json)
    }
  ]);
}
// 是否显示天花
/*
        var _json = JSON.stringify({
            "bShowCovering": 'true',
        });
*/
function UeS_tool_showcover(json) {
  if (!Current_Mode_Name) {
    return;
  }
  if (UeToU_Callbacks[CBN_ASKTOOLBAR]) {
    UeToU_Callbacks[CBN_ASKTOOLBAR]({
      idx: 1,
      isOpen: json.bShowCovering === "true"
    });
  }
  if (!(window.ue && window.ue.uecom)) {
    return;
  }
  console.log("UeS_tool_showcover go", JSON.stringify(json));
  ExcuteObjectFunctionWithJson([
    {
      funcname: "ShowOrHideCovering",
      json: JSON.stringify(json)
    }
  ]);
}
// 是否开启测量
/*
        var _json = JSON.stringify({
            "bEnableMeasure": 'true',
        });
*/
function UeS_tool_enablemeasure(json, isChangeState = true) {
  if (!Current_Mode_Name) {
    return;
  }
  if (isChangeState && UeToU_Callbacks[CBN_ASKTOOLBAR]) {
    UeToU_Callbacks[CBN_ASKTOOLBAR]({
      idx: 2,
      isOpen: json.bEnableMeasure === "true"
    });
  }
  if (!(window.ue && window.ue.uecom)) {
    return;
  }
  console.log("UeS_tool_enablemeasure go", JSON.stringify(json));
  ExcuteObjectFunctionWithJson([
    {
      funcname: "SceneMeasure",
      json: JSON.stringify(json)
    }
  ]);
}
// 是否显示楼层筛选设备管线
/*
        var _json = JSON.stringify({
            "bShowPipeLine": 'true', 
        });
*/
function UeS_tool_showpipe(json) {
  if (!Current_Mode_Name) {
    return;
  }
  if (UeToU_Callbacks[CBN_ASKTOOLBAR]) {
    UeToU_Callbacks[CBN_ASKTOOLBAR]({
      idx: 3,
      isOpen: json.bShowPipeLine === "true"
    });
  }
  if (!(window.ue && window.ue.uecom)) {
    return;
  }
  console.log("UeS_tool_showpipe go", JSON.stringify(json));
  ExcuteObjectFunctionWithJson([
    {
      funcname: "ShowOrHideStoreyPipeLine",
      json: JSON.stringify(json)
    }
  ]);
}
// 是否开启场景漫游
/*
        var _json = JSON.stringify({
            "bEnableRoaming": 'true',
        });
*/
function UeS_tool_enableroaming(json, isChangeState = true) {
  if (!Current_Mode_Name) {
    return;
  }
  if (isChangeState && UeToU_Callbacks[CBN_ASKTOOLBAR]) {
    UeToU_Callbacks[CBN_ASKTOOLBAR]({
      idx: 4,
      isOpen: json.bEnableRoaming === "true"
    });
  }
  if (!(window.ue && window.ue.uecom)) {
    return;
  }
  console.log("enableroaming go", JSON.stringify(json));
  ExcuteObjectFunctionWithJson([
    {
      funcname: "SceneRoaming",
      json: JSON.stringify(json)
    }
  ]);
}

////////////////////////////////////////////////////////////
// 与ue场景间交互 from UE Scene to UI
// 场景内尝试空间定位
/*
{
  // 园区
  park_mid : ,
  // 楼座
  building_mid :,
  // 楼层
  storey_mid : ,
}
*/
const CBN_ASKNAVSPACE = "askNavSpace";
// 场景内请求提示
/*
{
  // 园区
  x : ,
  // 楼座
  y :,
  // 楼层
  type : "equipment" "attachequip","BuildingEquipInfo"
  mid:
}
*/
const CBN_ASKSHOWTIP = "askShowTip";

// 场景内空间导航结束
const CBN_ONSPACESTATEFINISH = "onSpaceStateFinish";
// 场景内点击关闭摄像头按钮
// {
//   name :
// }
const CBN_ASKCLOSECAMERA = "askCloseCamera";
// 场景内指南针转动，单位角度
// {
//   angle:45.6
// }
const CBN_ONCOMPASSRUN = "onCompassRun";
// 显示全屏装载页面
// {
//   show:true,//false  显示隐藏
// }
//window.ues_call_ui_option("askShowLoading",{show:true});
const CBN_ASKSHOWLOADING = "askShowLoading";

//toolbar工具栏UE4 回调
// {
//   idx:1, //工具栏上第几个可用工具
//   isOpen:true //是打开还是关闭
// }
const CBN_ASKTOOLBAR = "askToolBar";

//  资产-进入精装修场景
const CBN_ASKINDECORATION = "askInDecoration";

const CBN_ASKINROAMING = "askInRoaming";

var UeToU_Callbacks = {};
function UeToU_setCallback(name, callback) {
  UeToU_Callbacks[name] = callback;
}

function UeS_init(mid) {
  //prono
  console.log("UeS_init---HTML_Init", `mid:${mid}`);
  ExcuteObjectFunctionWithJson([
    {
      funcname: "HTML_Init",
      json: JSON.stringify({
        isInit: true,
        mid
      })
    }
  ]);

  window.ues_call_ui_option = function (name, param) {
    //不打印指针回调
    if ("onCompassRun" !== name) {
      console.log("ues_call_ui_option", name, param);
    }
    let func = UeToU_Callbacks[name];
    if (func) {
      try {
        func(param);
      } catch (err) {
        console.error("ues_call_ui_option", name, param, err);
      }
    }
  };
  window.ues_callback = function (str) {
    console.log("ues_callback", str);
    let obj = null;
    if (str) {
      try {
        obj = this.JSON.parse(str);
      } catch (error) {
        obj = null;
      }
      if (obj) {
        if ("enterMode" === obj.option) {
          // console.log("enterMode return", obj.param);
          Current_Mode_Name = obj.param;
          // if (last_space) {
          //   console.log("切空间定位");
          //   UeS_navigateSpace(last_space);
          // }
          return;
        }
      }
    }
  };
}

function UeS_set_nav(UE4_obj) {
  console.log("UeS_set_nav---Building_NavInit", JSON.stringify(UE4_obj));
  ExcuteObjectFunctionWithJson([
    {
      funcname: "Building_NavInit",
      json: JSON.stringify(UE4_obj)
    }
  ]);
}

function Ues_get_mode_name() {
  return Current_Mode_Name;
}

/*下载文件
    var _json = JSON.stringify({
            "URL": URL,
            "FileName": Name,
            "Headers": arrays,
            "Success": funcname,
            "Error": funcname
});
*/
function Ues_DownloadFile(UE4_obj) {

  console.log("---------------Ues_DownloadFile", JSON.stringify(UE4_obj));
  ExcuteObjectFunctionWithJson([
    {
      funcname: "DownloadFile",
      json: JSON.stringify(UE4_obj)
    }
  ]);
}

// 退出精装修场景
function Ues_outDecoration() {
  ExcuteObjectFunctionWithJson([
    {
      funcname: "outDecoration"
    }
  ]);
}

/**
 * 定位设备放大并移至中间  根据模型定位
 * @param {string} id 设备id
 * @param {string} type 设备类型: space-房间 parking-车位 equipment-设备 asset-资产
 */
function Ues_positionInCenter(UE4_obj) {
  ExcuteObjectFunctionWithJson([
    {
      funcname: "PositioningInCenter",
      json: JSON.stringify(UE4_obj)
    }
  ]);
}

//切换园区
function UeS_ChangePark(mid) {
  ExcuteObjectFunctionWithJson([
    {
      funcname: "ChangeParkURL",
      json: JSON.stringify({
        mid
      })
    }
  ]);
}

//修改空间信息
function UeS_spaceChangeInfo(spaceInfo) {
  ExcuteObjectFunctionWithJson([
    {
      funcname: "Space_ChangerInfo",
      json: JSON.stringify({
        Spaces: spaceInfo
      })
    }
  ]);
  console.log("UeS_space_changerInfo---Space_ChangerInfo", JSON.stringify(spaceInfo));
}
// 创建热力图
function UeS_createHeatMap() {
  ExcuteObjectFunctionWithJson([
    {
      funcname: "HeatMap_Create",
      json: ""
    }
  ]);
  console.log("HeatMap_Create");
}

// 更换热力图部分参数
// var ChangeParams={
//   "Attenuation_Length":3000, //衰减范围 厘米
//   "Enum":0,  //点类型 0柱状 1圆柱 2圆点 3方片
//   "PointSize":80, //直径 厘米
//   "Value_Min":0, //最小值
//   "Value_Max":1,  //最大值
//   "color_Min":"(R=0.000000,G=0.395246,B=1.000000,A=1.000000)",  //最小值颜色
//   "color_center":"(R=0.480579,G=0.040761,B=1.000000,A=1.000000)",  //中间值颜色
//   "color_Max":"(R=1.000000,G=0.000000,B=0.000000,A=1.000000)",  //最大值颜色
//   "isDefaultColor":true,  //是否启用最小值保底颜色
//   "color_default":"(R=0.000000,G=0.319079,B=0.807292,A=1.000000)",  //最小值更换颜色
//   "color_Select":"(R=0.245154,G=0.559337,B=0.614583,A=1.000000)",  //选中颜色
//   "color_Opacity":0.8,
// }
function UeS_heatMapChangeParams(params) {
  ExcuteObjectFunctionWithJson([
    {
      funcname: "HeatMap_ChangeParams",
      json: JSON.stringify(params)
    }
  ]);
  console.log("HeatMap_ChangeParams", JSON.stringify(params));
}

// var ChangeValue={
//   "equipIDs":[
//       "id1",
//       "id2",
//   ],//环境测点的id
//   "values":[
//       12,
//       13,
//   ],//当前的数值
//   "equip_uis":[
//       "{}",
//       "id2",
//   ]//UI的内容 可带单位
// }
function UeS_heatMapChangeValue(params) {
  ExcuteObjectFunctionWithJson([
    {
      funcname: "HeatMap_ChangeValue",
      json: JSON.stringify(params)
    }
  ]);
  console.log("HeatMap_ChangeValue", JSON.stringify(params));
}
function UeS_destoryHeatMap() {
  ExcuteObjectFunctionWithJson([
    {
      funcname: "HeatMap_Destory",
      json: ""
    }
  ]);
  console.log("HeatMap_Destory");
}

function UeS_heatMapSelectMore(val) {
  ExcuteObjectFunctionWithJson([
    {
      funcname: "HeatMap_SelectMore",
      json: val + ""
    }
  ]);
  console.log("HeatMap_SelectMore", val + "");
}

module.exports = {
  UeS_init,
  LogicModuleName,

  Ues_DownloadFile,

  UeS_close,
  UeS_mini,
  UeS_enterMode,
  UeS_navigateSpace,
  UeS_selectEquip,
  UeS_filterEquip,
  UeS_filterEquipGroup,
  Ues_SelectEquipGroup,
  Ues_ChangeEquipGroup,
  UeS_modelState,
  UeS_spaceColor,
  UeS_spaceSelect,
  UeS_spaceChangeInfo,
  UeS_filterAsset,
  UeS_selectAsset,
  UeS_addVideoPlay,
  UeS_modifyVideoPlay,
  UeS_delVideoPlay,
  Ues_SaveScreenshot,
  UeS_pakingFilter,
  UeS_pakingSelect,
  UeS_showMiniMap,
  UeS_enableCompass,
  UeS_resetCompass,

  CBN_ASKNAVSPACE,
  CBN_ASKSHOWTIP,
  CBN_ONSPACESTATEFINISH,
  CBN_ASKCLOSECAMERA,
  CBN_ONCOMPASSRUN,
  CBN_ASKSHOWLOADING,
  CBN_ASKTOOLBAR,
  CBN_ASKINDECORATION,
  CBN_ASKINROAMING,
  UeToU_setCallback,
  UeS_set_nav,
  Ues_get_mode_name,

  UeS_tool_trans,
  UeS_tool_showpipe,
  UeS_tool_showcover,
  UeS_tool_showaxis,
  UeS_tool_enablemeasure,
  UeS_tool_enableroaming,
  Ues_outDecoration,
  Ues_positionInCenter,
  UeS_ChangePark,
  UeS_createHeatMap,
  UeS_heatMapChangeParams,
  UeS_heatMapChangeValue,
  UeS_destoryHeatMap,
  UeS_heatMapSelectMore
};
