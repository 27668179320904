import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { bimWhiteColor } from "assets/jss/bim-webs.js";

const styles = {
  st0: { display: "none" },
  st1: { fillOpacity: 7.0e-2 },
  st2: { fillOpacity: 0.12 },
  st3: {
    fill: "none",
    stroke: bimWhiteColor[0],
    strokeWidth: 2,
    strokeLinejoin: "round",
    strokeOpacity: 0.1
  },
  st4: { fill: "#ECECEC" },
  st5: { opacity: 0.8, fill: "#F72C3E", enableBackground: "new" },
  st6: { fill: "#F72C3E", fillOpacity: 0.8, stroke: bimWhiteColor[0] }
  // .st0{display:none;}
  // .st1{fill-opacity:7.000000e-02;}
  // .st2{fill-opacity:0.12;}
  // .st3{fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linejoin:round;stroke-opacity:0.1;}
  // .st4{fill:#ECECEC;}
  // .st5{opacity:0.8;fill:#F72C3E;enable-background:new    ;}
  // .st6{fill:#F72C3E;fill-opacity:0.8;stroke:#FFFFFF;}
};

const useStyles = makeStyles(styles);

// const CompassSvg = () => (
export default function CompassSvg(props) {
  const classes = useStyles();
  return (
    <svg
      version="1.1"
      // xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1024 1024"
      //   style="enable-background:new 0 0 1024 1024;"
      xmlSpace="preserve"
      //   style={{}}
      {...props}
    >
      <g id="图层_1" className={classes.st0}></g>
      <g id="图层_2">
        <g>
          <circle
            id="圆_拷贝_3"
            className={classes.st1}
            cx="512"
            cy="512"
            r="383.8"
          />
          <g>
            <circle
              id="圆_拷贝_3-2"
              className={classes.st2}
              cx="512"
              cy="512"
              r="345.4"
            />
          </g>
          <g>
            <circle
              id="圆_拷贝_3-2_1_"
              className={classes.st3}
              cx="512"
              cy="512"
              r="345.4"
            />
          </g>
          <path
            id="边_拷贝_2"
            className={classes.st4}
            d="M595,511.8L595,511.8l-69.8-260.1c-1.3-6.9-7.9-11.4-14.7-10.1c-5.1,1-9.2,5-10.1,10.1
			L429,511.8H429v0.2v0.2h0.1l71.2,260.2c1.3,6.9,7.9,11.4,14.7,10.1c5.1-1,9.2-5,10.1-10.1l69.8-260.1h0.1V512L595,511.8L595,511.8
			L595,511.8z M512.7,512l61.2,1l-61.4,222L450,513L512.7,512"
          />
          <path
            id="红指针_拷贝_2"
            className={classes.st5}
            d="M598.4,512L512,246.5L425.6,512H598.4z M512,473.6c21.2,0,38.4,17.2,38.4,38.4
			s-17.2,38.4-38.4,38.4s-38.4-17.2-38.4-38.4S490.8,473.6,512,473.6z"
          />
          <circle
            id="椭圆_5"
            className={classes.st6}
            cx="512"
            cy="512"
            r="28.8"
          />
        </g>
      </g>
    </svg>
  );
}
