import { xFetch } from "util/webfetch";

export const getSpacesByStorey = mid =>
  xFetch("spacebystorey", {
    params: { body: JSON.stringify({ storey_mid: mid }) }
  });

const navspace = {
  getSpacesByStorey
}
export default navspace;
