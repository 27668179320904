import React from 'react'
import { withStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button";
import Input from "@material-ui/core/Input";
import Checkbox from "components/CustomInputs/Checkbox";
import { connect } from 'react-redux'
import { login } from 'api/auth';
import { setBaseToken } from 'util/webfetch';

import { InitSpaceTree } from "util/webfetch.js";
import navsp from "actions/navspace";
import auth from "actions/auth";
import toastAction from "actions/toast";
import Cookie from "util/cookie";
// import WebSocks from "util/websocks";
import md5 from 'js-md5';

import { bimYellowColor, bimWhiteColor } from "assets/jss/bim-webs.js";

const styles = {
    form: {
      backgroundColor: "#111111",
      opacity: 0.9,
      position: "absolute",
      top: "50%",
      left:"50%",
      transform: "translate(-50%, -50%)",
      flexDirection: 'column',
      alignItems: 'center',
      width: "7rem",
      height: "5rem"
    },
    optBtn: {
      position: "absolute",
      left:"50%",
      transform: "translateX(-50%)",
      bottom: "0.8rem",
      width: "3.55rem",
      height: "0.58rem",
      backgroundColor: bimYellowColor[0]
    },
    inputbox: {
        position: "absolute",
        width: "100%",
        height: "0.5rem"
    },
    checkboxWrap: {
        position: "absolute",
        width: "100%",
        height: "0.5rem",
        color: bimYellowColor[0],
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    inputname: {
        position: "absolute",
        width: "1rem",
        height: "0.5rem",
        lineHeight: "0.5rem",
        top: "0",
        left: "0.6rem",
        fontSize: "0.21rem",
        color: bimWhiteColor[0]
    },
    inputroot: {
        position: "absolute",
        width: "4rem",
        height: "0.52rem",
        lineHeight: "0.52rem",
        top: "0",
        left: "1.5rem",
        cursor: "pointer",
        border: "none",
        borderBottom: "1px solid " + bimWhiteColor[0],
        "&:after": {
          borderBottom: "1px solid " + bimWhiteColor[0],
        }
    },
    input: {
      fontSize: "0.4rem",
      height: "0.5rem",
      marginBottom: "0.02rem",
      color: bimWhiteColor[0],
      backgroundColor: "inherit"
    }
};

class Login extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            account: '',
            password: '',
            isRememberUser: true
        }
    }

    componentDidMount() {
        // const isRememberUser = Cookie.getCookie("isRememberUser");

        // if (isRememberUser === "true") {
        //     let user = Cookie.getCookie('user');
        //     const account = JSON.parse(user).username;
        //     const password = Cookie.getCookie('pwd');
        //     this.setState({
        //         account,
        //         password,
        //         isRememberUser: true
        //     })
        // } else {
        //     this.setState({
        //         account: "",
        //         password: "",
        //         isRememberUser: false
        //     })
        // }
    }

    componentWillUnmount() {
    }

    onEnter = e => {
        if (e.keyCode === 13) {
          this.onSubmit()
        }
    }

    onButtonClick = () => {
        this.onSubmit()
    }

    handleSocketMsg = msg => {
        // console.log("handleSocketMsg", msg);
    };
    
    onSubmit = () => {
        const { account, password, isRememberUser } = this.state
        let data = {
            account: account,
            password: md5(password),
            remember: isRememberUser ? 1 : 0
        }
        if (!account || !password) {
            window.store.dispatch(
                toastAction.toastVisible.set({
                  show: true,
                  msg: "请输入用户名或密码"
                })
            );
            return;
        }
        login(data).then( response => {
            let token = response.userinfo.token
            const expireTime = isRememberUser ? response.userinfo.expire_time * 1000 : 0;
            console.log("response", response)
            setBaseToken(token)
            this.props.setUserInfo(response.userinfo)
            Cookie.setCookie('token', token, expireTime)
            // Cookie.setCookie('pwd', password, expireTime);
            // Cookie.setCookie('isRememberUser', isRememberUser, expireTime);
            Cookie.setCookie('user', JSON.stringify(response.userinfo), expireTime)
            // WebSocks.start({
            //     token: token,
            //     callback: this.handleSocketMsg
            // });
            InitSpaceTree( (tree, allTree) => {
                this.props.setTree(tree);
                this.props.setParkTree(allTree); //设置园区切换列表数据
            });
            this.props.history.push({pathname: '/security'})
        })
    }

    handleCheckboxChange = (evt) => {
        this.setState({
            isRememberUser: evt.target.checked
        })
    }

    render() {
        const {isRememberUser} = this.state;
        const { classes } = this.props;

        return (
            <div className={classes.form}>
                <div className={classes.inputbox} style={{top:"1rem"}}>
                    <span className={classes.inputname}>用户名</span>
                    <Input
                        classes={{ root: classes.inputroot, input: classes.input }}
                        name="account"
                        id="account"
                        value={this.state.account}
                        onChange={e => { this.setState({ account: e.target.value }) }}
                    />
                </div>
                <div className={classes.inputbox} style={{top:"2rem"}}>
                    <span className={classes.inputname}>密<span style={{opacity:0}}>密</span>码</span>
                    <Input
                        classes={{ root: classes.inputroot, input: classes.input }}
                        name="password"
                        id="password"
                        type="password"
                        value={this.state.password}
                        onChange={e => { this.setState({ password: e.target.value }) }}
                    />
                </div>
                <div className={classes.checkboxWrap} style={{top: "2.9rem"}}>
                    <Checkbox checked={isRememberUser} onChange={this.handleCheckboxChange} />
                    <span>记住我</span>
                </div>
                <Button
                    key="login"
                    onClick={this.onSubmit}
                    className={classes.optBtn}
                    >
                    登录
                </Button>
            </div>
        )
    }
}

export default withStyles(styles)(
    connect(
    ({
        navspace: { parkTreeState }
    }) => {
        return {
            parkTreeState
        };
    },
      dispatch => ({
        setTree: tree => dispatch(navsp.spaceTree.set(tree)),
        setParkTree: parktree => dispatch(navsp.parkTree.set(parktree)),
        setUserInfo: data => dispatch(auth.userInfo.set(data))
      })
    )(Login)
  );
  