import React, { Component } from "react";
import classnames from "classnames";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/core/styles";

import { bimYellowColor, bimWhiteColor } from "assets/jss/bim-webs.js";
import { HEADBAR_HEIGHT } from "assets/jss/bim-webs.js";
import {
    UeS_modifyVideoPlay,
    Ues_SaveScreenshot
} from "variables/uescene";

import SwitchButton from "../SwitchButton/StatusSwitch";

const styles = {
    root: {},
    paper: {
        width: "14.4rem",
        maxWidth: "14.4rem",
        height: "8.1rem",
        background: "#000000",
        overflow: "hidden"
    },
    topBar: {
        width: "100%",
        height: "0.64rem",
        padding: "0 0.19rem",
        boxSizing: "border-box",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    titleLabel: {
        fontSize: "0.18rem",
        fontWeight: 400,
        color: bimYellowColor[0]
    },
    btns: {
        display: "flex",
        alignItems: "center"
    },
    btn: {
        fontSize: "0.24rem",
        color: bimWhiteColor[0],
        float: "right",
        marginRight: "0.1rem",
    },
    content: {
        width: "100%",
        height: "6.82rem"
    },
    bottomBar:{
        width: "100%",
        height: "0.64rem",
        padding: "0 0.19rem",
        boxSizing: "border-box",
        textAlign: "center",
        lineHeight: "0.64rem",
        color: "#fff",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    ctrlSpeed: {
        flex: 1
    },
    startAndStop: {
        margin: "0 0.5rem"
    },
    otherOpt: {
    },
    optBtn: {
        cursor: "pointer"
    }
};
class MaxCamera extends Component {

    constructor(props) {
        super(props);
        this.cameraBox = React.createRef();
    };
    iconBtn = () => {
        const {classes} = this.props;
        return <span style={{borderRadius: "50%"}} className={classnames(classes.lightIconBg, "icon", "font_family")}>&#xe973;</span>
    }

    onDialogOpened = () => {
        let rect = this.cameraBox.current.getBoundingClientRect();
        UeS_modifyVideoPlay({
            video: [{ 
                x: rect.left,
                y: rect.top,
                w: rect.width,
                h: rect.height,
                isBottom: false,
                name:this.props.name
             }]
        },this.cameraBox.current);
    }

    handleScreenshot = () => {
        Ues_SaveScreenshot({
            name: this.props.name,
            FileName: "screenshot.png",
            shotWidth: "100",
            shotHeight: "100",
            Error: this.screenShotError
        })
    }

    screenShotError = (error) => {
      console.log(error)
    }

    render() {
        const {
            classes,
            title = "",
            open = false,
            lightInfo = null,
            marginTop = HEADBAR_HEIGHT,
            handleCloseCamera,
            handleTipsSwitchState,
            close,
            ...rest
        } = this.props;
        return (
            <Dialog
                classes={{ root: classes.root, paper: classes.paper }}
                style={{ top: marginTop }}
                // aria-labelledby="simple-dialog-title"
                open={open}
                onEntered={this.onDialogOpened}
                onExited={handleCloseCamera}
                // fullScreen
                {...rest}
            >
                <div className={classes.topBar}>
                    <div className={classes.titleLabel}>{title}</div>
                    <div className={classes.btns}>
                    {!!lightInfo && <div className={classes.btn}>
                        <SwitchButton 
                            switchOn={ lightInfo.status === "on"?true:false }
                            disabled={ lightInfo.status ==="offline"?true:false }
                            onChange={handleTipsSwitchState} size="small"
                            icon={this.iconBtn}
                            checkedIcon={this.iconBtn}
                        />
                    </div>}
                    <span
                        onClick={() => close("small")}
                        className={classnames(classes.optBtn, classes.btn, "icon", "font_family")}
                    >&#xe968;</span>
                    </div>
                </div>
                <div className={classes.content} ref={this.cameraBox}></div>
                <div className={classes.bottomBar}>
                    <div className={classes.ctrlSpeed}>
                        <span className={classnames(classes.optBtn, "icon", "font_family")} style={{fontSize: "0.24rem"}}>&#xe970;</span>
                        <span className={classnames(classes.startAndStop, classes.optBtn, "icon", "font_family")} style={{fontSize: "0.3rem"}}>&#xe972;</span>
                        <span className={classnames(classes.optBtn, "icon", "font_family")} style={{fontSize: "0.24rem"}}>&#xe969;</span>
                    </div>
                    <div className={classes.otherOpt}>
                        <span className={classnames(classes.optBtn, "icon", "font_family")} style={{fontSize: "0.26rem", marginRight: "0.27rem"}}
                            onClick={this.handleScreenshot}
                            >&#xe966;</span>
                        <span className={classnames(classes.optBtn, "icon", "font_family")} style={{fontSize: "0.26rem"}}>&#xe965;</span>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default withStyles(styles)(MaxCamera);