import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CompassSvg from "components/CustomSvg/Compass";

import {
  UeS_enableCompass,
  UeS_resetCompass,
  CBN_ONCOMPASSRUN,
  UeToU_setCallback
} from "variables/uescene";

const styles = {
  compass: {
    right: "0rem",
    display: "inline-block",
    position: "absolute",
    height: "1.2rem",
    width: "1.2rem",
    top: "0rem"
  }
};

class CompassComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { deg: 0 };
  }

  componentDidMount() {
    UeToU_setCallback(CBN_ONCOMPASSRUN, this.handleCompassRun);
    UeS_enableCompass(true);
  }

  componentWillUnmount() {
    UeToU_setCallback(CBN_ONCOMPASSRUN, null);
    UeS_enableCompass(false);
  }

  handleCompassRun = param => {
    //console.log("handleCompassRun", param);
    this.setState({ deg: param.angle });
  };

  handleClickCompass = () => {
    console.log("handleClickCompass");
    UeS_resetCompass();
  };

  render() {
    const { classes } = this.props; //, curSpace
    const { deg } = this.state;
    let rstr = `rotate(${deg}deg)`;
    return (
      <div className={classes.compass}>
        <CompassSvg
          onClick={this.handleClickCompass}
          style={{ transform: rstr }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(CompassComponent);
