import "whatwg-fetch";
//import {fetch as fetchPolyfill} from 'whatwg-fetch';

// import { MODULE_TREE } from "variables/equip";
import {
  //GetSpaceTree,
  GenSpaceTree,
  // GetProNo,
  GetParkMid
} from "variables/general";
// import { Ues_DownloadFile } from "variables/uescene";
import { getBaseUrl } from "./urls";

import toastAction from "actions/toast";
import Cookie from "util/cookie";
const BASE_URL = getBaseUrl();
var CURRENT_TOKEN = Cookie.getCookie('token') //|| getDefaultBaseToken();

const currentversion = "/v1/"; //API地址
const baseurlversion = BASE_URL + currentversion;

function setBaseToken(token) {
  CURRENT_TOKEN = token;
}

function getHeaderParkmid() {
  let parkmid = GetParkMid()
  if(window.store && window.store.getState().navspace.spaceTreeState.spaceTree) {
    parkmid =  window.store.getState().navspace.spaceTreeState.spaceTree[0].mid
  }
  return parkmid
}

function APIError(err) {
  this.name = "APIError";
  const { status, statusText, body = {} } = err;

  if(status === 401) { //token失效，重新登录
    window.location = "#/login"
    return "";
  }

  this.status = status;
  this.statusText = statusText;

  if (status === 0) {
    this.message = "网络错误";
  } else if (body.code === undefined) {
    this.message = `${statusText ? statusText : "服务不可用"}:${status}`;
  } else {
    this.time = body.time;
    this.code = body.code;
    this.message = body.message || body.msg || `服务错误:${status}`;
  }
  //this.stack = (new Error()).stack;
}
APIError.prototype = Object.create(Error.prototype);
APIError.prototype.constructor = APIError;

window.downloadToast = msg => {
  window.store.dispatch(
    toastAction.toastVisible.set({
      show: true,
      msg: msg
    })
  );
};

const xFetchUrl = (urlInput, data = {}) => {
  // console.log("=== start fetch:");
  const { method = "GET", params = {}, body } = data;
  
  const url = new URL(urlInput);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

  return window
    .fetch(url, {
      method,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        token: CURRENT_TOKEN,
        parkmid: getHeaderParkmid()
      },
      body: JSON.stringify(body)
    })
    .then(response => {
      // console.log("====response is", response);
      const { status, statusText } = response;
      if (status === 0 || status === 401) {
        const err = new APIError({ status, statusText });
        throw err;
      }

      // code	int	错误码 =0正常 >0错误
      // msg	string	错误信息
      // time	int	服务器时间
      // data	json
      // 统一response格式
      return response.json().then(body => {
        // console.log("=== fetch result:", { response, body });
        const { code, data } = body;
        if(code === 403) {
          window.location = "#/main"
        }
        if(code === 401) { //帐号已在其他设备登录或者token失效，请重新登录
          window.location = "#/login"
          return;
        }
        if (code !== 0) {
          const err = new APIError({
            status,
            statusText,
            body
          });

          throw err;
        }

        return data;
      });
    })
    .catch(err => {
      console.log("=== fetch error:", err, "------------");
      if(err.message || err.msg) {
        window.store.dispatch(
          toastAction.toastVisible.set({
            show: true,
            msg: err.message || err.msg
          })
        );
      }
      // TODO: 错误提示？
      if (err instanceof APIError) {
        throw err;
      }

      const e = new APIError(err);
      throw e;
    });
};

const xFetchRaw = (path, data = {}) => {
  let pathstr = path.split("/")
  if(pathstr[pathstr.length-1] === "undefined") {
    return
  }
  const url = new URL(path, BASE_URL);
  return xFetchUrl(url, data);
};
const xFetch = (path, data = {}) => xFetchRaw(currentversion + path, data);

function fetchUrl(url, callback) {
  window
    .fetch(baseurlversion + url, {
      method: "GET",
      //mode: "no-cors",
      //headers: uheaders
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        token: CURRENT_TOKEN,
        parkmid: getHeaderParkmid()
      }
    })
    .then(function(response) {
      if(response.status === 401) { //token失效，重新登录
        window.location = "#/login"
        return "";
      }
      return response.text();
    })
    .then(data => {
      if(!data) return;
      var ds = JSON.parse(data);
      if(ds.code === 403) {
        window.location = "#/main"
      }
      if(ds.code === 401) { //帐号已在其他设备登录，重新登录
        window.location = "#/login"
        return;
      }
      callback(ds);
      if (ds.code !== 0 && ds.msg) {
        window.store.dispatch(
          toastAction.toastVisible.set({
            show: true,
            msg: ds.msg
          })
        );
      }
    })
    .catch(e => {
      console.log("fetch error", e);
      //callback();
    });
}

function fetchUrlBody(url, bodystr, callback) {
  if (bodystr) {
    let urlto = url + "?body=" + encodeURIComponent(bodystr);
    fetchUrl(urlto, callback);
  } else {
    fetchUrl(url, callback);
  }
}

const EqpUrlFilters = {
  ems_query: "com/filters/items/ems_query",
  ems_cont_thdyn: "com/filters/items/ems_cont_thdyn",
  spc_query: "com/filters/items/spacequery",
  ams_view: "com/filters/items/assetquery",
  ils_pub: "com/filters/items/ilslist",
};

const EqpKeywordSearch = {
  ems_query: "equipment/keyword/search",
  ems_cont_thdyn: "equipment/keyword/search",
  spc_query: "equipment/keyword/search",
  ams_view: "ams/query/search/keyword",
  ils_pub: "ils/search/keyword"
};


const filtersGetPath = module => {
  return EqpUrlFilters[module];
  // TODO: 如果有其他模块
  // return EqpUrlFilters[module] || xxxxxFilters[module] || yyyyyFilers[module]
};

const KeywordSearch = module => {
  return EqpKeywordSearch[module];
  // TODO: 如果有其他模块
  // return EqpUrlFilters[module] || xxxxxFilters[module] || yyyyyFilers[module]
};

function InitSpaceTree(cb) {
  //if (!GetSpaceTree()) {
  fetchUrl("navs", msg => {
    console.log("InitSpaceTree", msg);
    if (msg && msg.data) {
      let allTree = msg.data.nav
      let nav = allTree.find((item)=>{return item.mid === GetParkMid()})
      let tree = GenSpaceTree([nav]);
      if (cb) {
        cb(tree, allTree);
      }
    }
  });
  //}
}
//获取楼层房间信息
function fetchSpace_storeyRooms(bodystr, callback) {
  fetchUrlBody("space/storey/rooms", bodystr, callback);
}

function fecthSecurityKeyAreas(bodystr, callback) {
  fetchUrlBody("scs/keyareas", bodystr, callback);
}
function fetchSceneEquipList(bodystr, callback) {
  let jsonBody = JSON.parse(bodystr);
  let module = jsonBody.module;
  delete jsonBody.module;
  fetchUrlBody("equiplist/" + module, JSON.stringify(jsonBody), callback);
}
function fetchSceneStatics(bodystr, callback) {
  let jsonBody = JSON.parse(bodystr);
  let module = jsonBody.module;
  delete jsonBody.module;
  fetchUrlBody("stats/" + module, JSON.stringify(jsonBody), callback);
}
function fetchSceneBadge(bodystr, callback) {
  let jsonBody = JSON.parse(bodystr);
  let module = jsonBody.module;
  delete jsonBody.module;
  fetchUrlBody("statusbar/" + module, JSON.stringify(jsonBody), callback);
}
function fetchSceneLoglst(bodystr, callback) {
  let jsonBody = JSON.parse(bodystr);
  let module = jsonBody.module;
  delete jsonBody.module;
  fetchUrlBody("hstlist/" + module, JSON.stringify(jsonBody), callback);
}
function fetchCameraRtsp(bodystr, callback) {
  fetchUrlBody("cctv/url", bodystr, callback);
}
//获取设备摄像头列表
function fetchEquipCameraDevices(bodystr, callback) {
  fetchUrlBody("cctv/relation", bodystr, callback);
}
//灯组切换状态
export const switchIls = params =>
  xFetch("ils/switch", {
    method: "PUT",
    body: params
  });
// 设备相关灯组
export const equipRelationLight = (bodystr, callback) =>
  fetchUrlBody("ils/relation-light", bodystr, callback);

function fetchAccessRecord(bodystr, callback) {
  fetchUrlBody("scs/access/record", bodystr, callback);
}

export {
  setBaseToken,
  xFetch,
  xFetchRaw,
  xFetchUrl,
  InitSpaceTree,
  fetchUrl,
  fetchUrlBody,
  fetchSpace_storeyRooms,
  fecthSecurityKeyAreas,
  fetchSceneEquipList,
  fetchSceneStatics,
  fetchSceneLoglst,
  fetchCameraRtsp,
  fetchEquipCameraDevices,
  fetchSceneBadge,
  filtersGetPath,
  fetchAccessRecord,
  KeywordSearch
};
