import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

// Use require.context to require reducers automatically
// Ref: https://webpack.github.io/docs/context.html
const context = require.context("./", false, /\.js$/);
const keys = context.keys().filter(item => ["./index.js"].indexOf(item) < 0);

// console.log(context);
const reducers = {};
keys.forEach(key => {
  // console.log(context(key));
  reducers[key.match(/([^/]+)\.js$/)[1]] = context(key).default;
});

// console.log("reducers are:", reducers);
export default combineReducers({
  ...reducers,
  form
});
