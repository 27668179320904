import React, { Component } from "react";
import classNames from "classnames";
import Slide from "@material-ui/core/Slide";

import { MODULE_NAMES } from "variables/general";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";

import { connect } from "react-redux";
import { checkPermKey } from "contexts/AuthContext";
// import loading from "actions/loading";

import {
  primaryBoxShadow,
  bimBlackColor,
  bimWhiteColor,
  bimYellowColor,
  FOOTBAR_HEIGHT
} from "assets/jss/bim-webs.js";

const styles = {
  footer: {
    // borderTop: "1px solid " + bimGrayColor[1],
    // boxSizing: "border-box",
    backgroundColor: bimBlackColor[1],
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: FOOTBAR_HEIGHT,
    color: bimWhiteColor[0]
  },
  list: {
    padding: 0,
    textAlign: "center",
    height: FOOTBAR_HEIGHT
  },
  itemNav: {
    width: "1.6rem"
  },
  // itemNav: {
  //   width: "160px"
  // },
  itemBtn: {
    padding: 0,
    margin: 0,
    display: "inline-block",
    width: "1.6rem",
    height: FOOTBAR_HEIGHT,
    textAlign: "center",
    verticalAlign: "top",
    cursor: "pointer"
  },
  itemText: {
    //...defaultFont,
    padding: 0,
    margin: 0,
    lineHeight: FOOTBAR_HEIGHT,
    fontSize: "0.2rem"
    // color: bimWhiteColor[0]
  },
  activeFont: {
    color: bimBlackColor[1]
  },
  activeSecond: {
    color: bimBlackColor[1]
  },
  yellowColor: {
    backgroundColor: bimYellowColor[0],
    ...primaryBoxShadow,
    "&:hover,&:focus": {
      backgroundColor: bimYellowColor[0],
      ...primaryBoxShadow
    }
  },
  iconitem: {
    backgroundColor: bimBlackColor[4],
    display: "inline-block",
    top: 0,
    zIndex: 2,
    position: "absolute",
    width: "0.92rem",
    height: FOOTBAR_HEIGHT
  },

  back: {
    display: "inlineBlock",
    top: 0,
    right: 0,
    position: "absolute",
    width: "0.92rem",
    height: FOOTBAR_HEIGHT
  },
  navTitle: {
    backgroundColor: bimBlackColor[5],
    color: bimYellowColor[0],
    padding: 0,
    margin: 0,
    position: "absolute",
    top: 0,
    display: "inline-block",
    width: "1.6rem",
    height: FOOTBAR_HEIGHT,
    textAlign: "center",
    verticalAlign: "top",
    cursor: "pointer"
  },
  itemTitle: {
    left: "2.55rem"
  },
  firstTitle: {
    left: "0.92rem"
  },
  itemBtnDisable: {
    padding: 0,
    margin: 0,
    display: "inline-block",
    width: "1.6rem",
    height: FOOTBAR_HEIGHT,
    textAlign: "center",
    verticalAlign: "top",
  },
  itemTextDisable: {
    padding: 0,
    margin: 0,
    lineHeight: FOOTBAR_HEIGHT,
    fontSize: "0.2rem",
    color: "#686868"
  },
};

class FooterComponent extends Component {
  constructor(props) {
    super(props)
    this.state = { cursel: 0, slideto: false, fromGoPreBtn: false, authPerm: null };
  }
  
  componentDidMount() {
    if(this.props.hashchange) {
      window.addEventListener('hashchange', this.change)
    }
    // const routeInd =  this.getRouteIndex();
    // if (routeInd.length === 2) {
    //   this.handleSelect(routeInd[0]);
    // }
    //console.log("MainComponent componentDidMount", this.props);
    // setInterval(() => {
    //   console.log("FooterComponent handleButton", this.state.slideto);
    //   this.setState({ slideto: !this.state.slideto });
    // }, 4000);
  }

  componentWillUnmount() {
    if(this.props.hashchange) {
      window.removeEventListener('hashchange', this.change)
    }
  }

  change = () => {
    let idx = this.getRouteIndex()
    if(idx) {
      this.handleSelect(idx[0])
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(!this.state.authPerm){
      //设置权限
      let keys = Object.keys(this.props.authChildren)
      let authPerm = this.props.authChildren[keys[0]]
      this.setState({authPerm})
    //   let permItree = []
    //   const { itree } = this.props;
    //   itree.forEach( item => {
    //     let check = checkPermKey(perm, item.uq_key)
    //     if(check) {
    //       let clone = JSON.parse(JSON.stringify(item))
    //       if(item.children) { //二级菜单
    //         clone.children = []
    //         item.children.forEach( subitem => {
    //           if(checkPermKey(perm, subitem.uq_key)) {
    //             clone.children.push(subitem)
    //           }
    //         })
    //       }
    //       permItree.push(clone)
    //     }
    //   })
    //   console.log("footbar permItree", permItree)
      // this.setState({permItree})
    }
  }

  getRouteIndex() {
    const { path } = this.props;
    let href = window.location.href;
    let idx = href.indexOf(path);
    if (idx <= 0) {
      return null;
    }
    let mname = href.substring(idx + path.length, href.length);
    // console.log("mname", mname);
    return this.getModuleIndex(mname);
  }

  getModuleIndex(fname) {
    const { itree } = this.props;
    for (let i = 0, l = itree.length; i < l; ++i) {
      if (fname === itree[i].field) {
        return [i];
      }
      if (itree[i].children) {
        let children = itree[i].children;
        for (let j = 0, k = children.length; j < k; ++j) {
          if (fname === children[j].field) {
            return [i, j];
          }
        }
      }
    }
    return null;
  }

  handleSelect = idx => {
    const { path, itree } = this.props;
    let rout = itree[idx];
    if (rout.children) {
      // this.setState({ slideto: !this.state.slideto });
      this.setState({ cursel: idx + 1, slideto: true });
      //默认跳转到第一个子节点
      // window.location = "#" + path + rout.children[0].field;
      //根据权限调转子节点
      for(let i=0;i<rout.children.length;i++) {
        if(checkPermKey(this.state.authPerm, rout.children[i].uq_key)) {
          window.location = "#" + path + rout.children[i].field;
          break;
        }
      }
      return;
    }

    this.setState({ cursel: 0 });
    window.location = "#" + path + rout.field;
  };

  handleBack = cursel => {
    if (this.state.cursel) {
      this.setState({ cursel: 0, slideto: false });
      return;
    }
    this.setState({ cursel: -1, slideto: false });
  };

  hanldeSecondSelectMenu = () => {
    const { path, itree } = this.props;
    const routeidxs = this.getRouteIndex();
    const current = itree[routeidxs[0]].children[0];
    this.handleLocate(path + current.field);
  };

  handleGoPrePage = () => {
    this.setState({ slideto: false, fromGoPreBtn:true });
  };

  handleLocate = to => {
    this.setState({ cursel: 0 });
    window.location = "#" + to;
  };

  handleReturnMain = () => {
    // this.setState({ slideto: !this.state.slideto });
    // this.props.setLoading(true);
    window.location = "#main";
    // setTimeout(() => {
    //   window.location = "#main";
    // }, 100);
  };

  renderSecond(routeidxs) {
    const { classes, path, itree } = this.props;
    const { cursel } = this.state;

    if (cursel > 0 || (routeidxs && 2 === routeidxs.length && cursel === 0)) {
      let sid = cursel > 0 ? cursel - 1 : routeidxs[0];
      let sel = itree[sid];
      let cdn = sel.children;

      let curchd = -1;
      let routcur = false;
      if (routeidxs && 2 === routeidxs.length) {
        routcur = sid === routeidxs[0];
        if (routcur) {
          curchd = routeidxs[1];
        }
      }

      return (
        <div style={{ width: "100%", height: "100%" }}>
          <List className={classes.list}>
            {cdn.map((data, key) => {
              let actived = routcur && key === curchd;
              let actButton = classNames({
                [" " + classes["yellowColor"]]: actived
              });
              let actColor = classNames({
                [" " + classes.activeSecond]: actived
              });
              if(checkPermKey(this.state.authPerm, data.uq_key)) {
                return (
                  <ListItem
                    key={key + 1}
                    className={classNames(classes.itemBtn, actButton)}
                    onClick={() => this.handleLocate(path + data.field)}
                  >
                    <ListItemText
                      primary={data.name}
                      className={classNames(classes.itemText, actColor)}
                      disableTypography={true}
                    />
                  </ListItem>
                );
              } else {
                return (
                  <ListItem
                    key={key + 1}
                    className={classes.itemBtnDisable}
                  >
                    <ListItemText
                      primary={data.name}
                      className={classes.itemTextDisable}
                      disableTypography={true}
                    />
                  </ListItem>
                );
              }
            })}
          </List>
          {/* <div className={classes.back} onClick={this.handleBack}>
            <span
              className={"icon font_family"}
              style={{
                color: "#989898",
                fontSize: "0.4rem",
                lineHeight: FOOTBAR_HEIGHT,
                textAlign: "center",
                width: "100%",
                display: "inline-block"
              }}
            >
              &#xe944;
            </span>
          </div> */}
          <ListItem className={classNames(classes.itemTitle, classes.navTitle)} onClick={this.hanldeSecondSelectMenu}>
            <ListItemText
              primary={`${sel.name} >`}
              className={classes.itemText}
              disableTypography={true}
            />
          </ListItem>
        </div>
      );
    }
  }

  render() {
    const { classes, path, itree } = this.props; //, navdata
    const curAddr = path.slice(1, -1);
    const curModule = MODULE_NAMES.filter(item => {
      return item.addr === curAddr;
    });
    const modeleName = curModule[0].name;
    const { fromGoPreBtn } = this.state;
    let routeidxs = this.getRouteIndex();
    let routcur = -1;
    if (!fromGoPreBtn && routeidxs && routeidxs.length > 0) {
      routcur = routeidxs[0];
    }

    let slideto = this.state.slideto;

    return (
      <div className={classes.footer}>
        <Slide
          direction="right"
          in={!slideto}
          mountOnEnter
          unmountOnExit
          timeout={{
            enter: 700,
            exit: 1000
          }}
        >
          <div
            style={{
              display: "inlineBlock",
              // background: "rgba(0,255,0,0.5)",
              width: "100%",
              height: "100%",
              top: 0,
              position: "absolute"
            }}
          >
            <List className={classes.list}>
              {itree.map((data, key) => {
                let actived = (key === routcur && !data.children);
                let actButton = classNames({
                  [" " + classes["yellowColor"]]: actived
                });
                let actColor = classNames({
                  [" " + classes.activeFont]: actived
                });
                if(checkPermKey(this.state.authPerm, data.uq_key)) {
                  return (
                    <ListItem
                      key={key}
                      className={classes.itemBtn + actButton}
                      onClick={() => this.handleSelect(key)}
                    >
                      <ListItemText
                        primary={data.name}
                        className={classNames(classes.itemText, actColor)}
                        disableTypography={true}
                      />
                    </ListItem>
                  );
                } else {
                  return (
                    <ListItem
                      key={key}
                      className={classes.itemBtnDisable}
                    >
                      <ListItemText
                        primary={data.name}
                        className={classes.itemTextDisable}
                        disableTypography={true}
                      />
                    </ListItem>
                  );
                }
              })}
            </List>
          </div>
        </Slide>
        <Slide
          direction="left"
          in={slideto}
          mountOnEnter
          unmountOnExit
          timeout={{
            enter: 1000,
            exit: 700
          }}
        >
          <div
            style={{
              display: "inlineBlock",
              // background: "rgba(0,0,255,0.5)",
              width: "100%",
              height: "100%",
              top: 0,
              position: "absolute"
            }}
          >
            {this.renderSecond(routeidxs)}
          </div>
        </Slide>
        <div className={classes.iconitem}>
          <span
            className={"icon font_family"}
            style={{
              color: "#989898",
              fontSize: "0.4rem",
              lineHeight: FOOTBAR_HEIGHT,
              textAlign: "center",
              width: "100%",
              display: "inline-block"
            }}
          >
            &#xe963;
          </span>
        </div>
        <ListItem className={classNames(classes.firstTitle, classes.navTitle)}>
            <ListItemText
              style={{
                cursor: "default"
              }}
              primary={`${modeleName} >`}
              className={classes.itemText}
              disableTypography={true}
            />
          </ListItem>
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(
    ({ auth: { authChildren } }) => {
      return {
        authChildren
      };
    }
  )(FooterComponent)
);

// export default withStyles(styles)(
//   connect(
//     ({ loading: { loadingState } }) => {
//       return loadingState;
//     },
//     dispatch => ({
//       setLoading: open => dispatch(loading.loadingFrame.set(open))
//     })
//   )(FooterComponent)
// );
