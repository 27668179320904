import React, { Component, useRef } from "react";
// import ReactDOM from "react-dom";
import { withStyles } from "@material-ui/core/styles";

import { Virtuoso } from "react-virtuoso";
import { Scrollbars } from "react-custom-scrollbars";
import { bimWhiteColor } from "assets/jss/bim-webs.js";
const styles = {
  // line: {
  //   padding: 0
  // }
};

const ListScrollContainer = ({ reportScrollTop, scrollTo, children }) => {
  const elRef = useRef(null);

  scrollTo(scrollTop => {
    elRef.scrollTo({ top: scrollTop });
  });

  return (
    <Scrollbars
      // id={"listscrollxx"}
      autoHide
      style={{ width: "100%", height: "100%" }}
      ref={elRef}
      onScroll={e => reportScrollTop(e.target.scrollTop)}
      renderThumbVertical={props => (
        <div
          {...props}
          style={{ width: "0.08rem", background: bimWhiteColor[0] }}
        />
      )}
    >
      {children}
    </Scrollbars>
  );
};

class ListComponent extends Component {
  static defaultProps = {
    datas: [],
    isend: false
  };

  $refVirtuoso = React.createRef();

  componentDidUpdate(prevProps, prevState) {
    if (!this.datasIsEqual(prevProps.datas, this.props.datas)) {
      //console.log("ListComponent do update", this.$refVirtuoso.current);
      // scrollTop = 10;
      // document.getElementById('scrollBody').scrollTop = 200;
      //this.$refVirtuoso.current.scrollToIndex(0);
      // let d = document.getElementById("listscrollxx");
      // console.log("ListComponent do update", d);
      // d.scrollTop = 0;
      // const dom = ReactDOM.findDOMNode(this.$refVirtuoso.current);
      // console.log("ListComponent do update", dom);
      // dom.scrollTop = 0;
    }
  }

  datasIsEqual(ods, nds) {
    if (ods !== nds) {
      let newlen = nds ? nds.length : 0;
      let olelen = ods ? ods.length : 0;
      if (newlen < olelen) {
        console.log("ListComponent update");
        return false;
      }
      if (newlen > 0 && olelen > 0 && ods[0] !== nds[0]) {
        console.log("ListComponent update2");
        return false;
      }
    }
    return true;
  }

  doAskNext = () => {
    if (this.loading) {
      return;
    }
    const { loadnextcb } = this.props;
    if (loadnextcb) {
      loadnextcb(this);
    }
  };

  askNext = () => {
    if (this.props.isend) {
      return;
    }
    setTimeout(() => {
      this.doAskNext();
    }, 0);
  };

  render() {
    const {
      itemcomp,
      endcomp,
      datas,
      isend,
      selected,
      onClickItem,
      isGetList,
      ...rest
    } = this.props;

    if (!itemcomp) {
      return null;
    }

    let count = datas ? datas.length : 0;
    let total = count;
    if (endcomp) {
      count++;
    }

    return (
      <Virtuoso
        ref={this.$refVirtuoso}
        ScrollContainer={ListScrollContainer}
        style={{ width: "99%", height: "99%" }}
        totalCount={count}
        item={index => {
          if (index >= total) {
            this.askNext();
            return React.createElement(endcomp, { status: isend });
          }
          return React.createElement(itemcomp, {
            index,
            selected: selected === index,
            data: datas[index],
            onClick: () => {
              if (onClickItem) {
                onClickItem(index);
              }
            },
            ...rest
          });
        }}
      />
    );
  }
}

export default withStyles(styles)(ListComponent);
