import { call, put, takeEvery } from "redux-saga/effects";

import actions from "actions/filters";
import api from "api/filters";

function* getFilters(action) {
  const { meta } = action;
  const { module } = meta;

  try {
    const result = yield call(api.getFilters, module);

    yield put(actions.getFilters.end(result, { module }));
  } catch (err) {
    console.log("------------", err);
    yield put(actions.getFilters.end(err));
  }
}
/* eslint-disable */
export default function*() {
  yield takeEvery(actions.getFilters.do, getFilters);
}
