import { createActions } from "redux-actions";

import { defaultCreator } from "./defaults";

export default createActions({
  NAVIGATION_SPACE: { SET: mids => ({ mids }) },
  SPACE_TREE: { SET: tree => ({ tree }) },
  GET_SPACES_BY_STOREY: {
    DO: defaultCreator,
    END: defaultCreator
  },
  PARK_TREE: { SET: parktree => ({ parktree }) },
  GET_FIRE_SPACES_BY_STOREY: {
    DO: defaultCreator,
    END: defaultCreator
  }
});
