var Cookie = {
    setCookie(cname, cvalue, time){
        // var d = new Date();
        // d.setTime(d.getTime()+(exdays*24*60*60*1000));
        var expires = ";expires="+new Date(time);
        //document.cookie = cname+"="+cvalue+";domain=172.16.50.42"//+expires;
        let domain = document.domain;
        if (domain.endsWith("biaddti.com")) {
            const arr = domain.split(".");
            const len = arr.length;
            if (len <= 4) {
                domain = `${arr[len - 3]}.${arr[len - 2]}.${arr[len - 1]}`;
            }
        }
        console.log("domaindomain", domain)
        if (!time) {
            document.cookie = cname+"="+cvalue+";domain="+domain;
        } else {
            document.cookie = cname+"="+cvalue+";domain="+domain+expires;

        }
    },
    getCookie(cname){
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i=0; i<ca.length; i++) {
            var c = ca[i].trim();
            if (c.indexOf(name)===0) { return c.substring(name.length,c.length); }
        }
        return "";
    },
    checkCookie(){
        var user=this.getCookie("username");
        if (user!==""){
            alert("欢迎 " + user + " 再次访问");
        }
        else {
            user = prompt("请输入你的名字:","");
              if (user!=="" && user!=null){
                this.setCookie("username",user,30);
            }
        }
    }
}

export default Cookie