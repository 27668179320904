/*!
 =========================================================
 * 
 =========================================================
*/

// ##############################
// // // Function that converts from hex color to rgb color
// // // Example: input = #9c27b0 => output = 156, 39, 176
// // // Example: input = 9c27b0 => output = 156, 39, 176
// // // Example: input = #999 => output = 153, 153, 153
// // // Example: input = 999 => output = 153, 153, 153
// #############################
import { getColors } from "./colors";

const { clBack, clFront, clActive, clGray } = getColors();

const hexToRgb = input => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};

// ##############################
// // // Variables - Styles that are used on more than one component
// #############################

const drawerWidth = 260;

const transition = {
  transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
};

const container = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto"
};

// yanf add
const HEADBAR_HEIGHT = "0.56rem";
const FOOTBAR_HEIGHT = "0.78rem";
const SCENE_RIGHT_WIDTH = "4.2rem"; //"4.2rem";
const STATEBAR_HEIGHT = "0.48rem";
const TOOLBAR_HEIGHT = "0.32rem";
const SNV_BTN_HEIGHT = "0.48rem";
const SNV_STOREY_BOTTOM = "1.8rem"; //174

const STATEBAR_BTNWIDTH = "1.6rem";
const SEARCHBAR_WIDTH = "4rem";
const SEARCHBAR_HEIGHT = "6rem";

// var bimBlackColor = [clBack[0], clBack[1], clBack[2], clBack[3], clBack[4], clBack[5], clBack[6], clBack[7], clBack[8], clBack[9], clBack[10]];
var bimBlackColor = [...clBack];
// bimBlackColor[2] = "rgba(" + hexToRgb(bimBlackColor[0]) + ",.7)";
// bimBlackColor[3] = "rgba(" + hexToRgb(bimBlackColor[0]) + ",.5)";
var bimWhiteColor = [clFront[0], clFront[1], clFront[2]]; //"#EAEAEA"
// bimWhiteColor[1] = "rgba(" + hexToRgb(bimWhiteColor[0]) + ",.8)";

const bimYellowColor = [clActive[0], clActive[1], clActive[2]]; //"#E2CC4B"
const bimGrayColor = [clGray[0], clGray[1]];
// const bimGrayColor = ["#A09C9C", "#515151"];
const bimRedColor = ["#d63636"];

// const primaryBoxShadow = {
//   boxShadow:
//     "0 4px 20px 0 rgba(17,17,17,.14), 0 7px 10px -5px rgba(" +
//     hexToRgb("#9c27b0") +
//     ",.4)"
// };

const primaryBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(bimBlackColor[0]) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb("#9c27b0") +
    ",.4)"
};

const FONT_SIZE_BIG = 36;
const FONT_SIZE_MID = 24;
const FONT_SIZE_NXT = 20;
const FONT_SIZE_SML = 16;

const LINE_SIZE_MID = 30;
const LINE_SIZE_NXT = 24;

const bimSeriesColors = [
  "#ff6f79",
  "#fa943f",
  "#f7d400",
  "#40c598",
  "#53a0f8",
  "#b072ed",
  "#f3a9a9",
  "#b8b601",
  "#74d578",
  "#396a93",
  "#8c89fc",
  "#ff5454",
  "#c9a05f",
  "#e2e441",
  "#61c846",
  "#0872b6",
  "#81639f",
  "#ff9b6f",
  "#ceaa2d",
  "#81ac7b",
  "#5071fd",
  "#773cb2",
  "#5c6279",
  "#5868b0"
];

const bimWaterColors = [
  "#402af8",
  "#74cafb",
  "#c1fbf0",
  "#7f73fa",
  "#fbe0fd",
  "#4cb59a",
  "#efe267",
  "#f6b3e0",
  "#ff946e",
  "#ffcda2",
  "#f8a4a4",
  "#fdca71",
  "#c4e36c",
  "#fa9f68",
  "#addbed",
  "#bebefb",
  "#5a85f1",
  "#6ce3ba",
  "#6eac5d",
  "#aca75d",
  "#ff6e6e",
  "#d93d33",
  "#d93379",
  "#ae66eb"
];

export {
  hexToRgb,
  //variables
  drawerWidth,
  transition,
  container,
  // boxShadow,
  // card,
  // defaultFont,
  //
  // primaryColor,
  // warningColor,
  // dangerColor,
  // successColor,
  // infoColor,
  // roseColor,
  // grayColor,
  // blackColor,
  // whiteColor,
  primaryBoxShadow,
  // infoBoxShadow,
  // successBoxShadow,
  // warningBoxShadow,
  // dangerBoxShadow,
  // roseBoxShadow,

  // yanf add
  bimBlackColor,
  bimGrayColor,
  bimWhiteColor,
  bimYellowColor,
  // bimScrollColor,
  bimRedColor,
  FONT_SIZE_BIG,
  FONT_SIZE_MID,
  FONT_SIZE_NXT,
  FONT_SIZE_SML,
  LINE_SIZE_MID,
  LINE_SIZE_NXT,
  HEADBAR_HEIGHT,
  FOOTBAR_HEIGHT,
  STATEBAR_HEIGHT,
  STATEBAR_BTNWIDTH,
  TOOLBAR_HEIGHT,
  SCENE_RIGHT_WIDTH,
  SEARCHBAR_WIDTH,
  SEARCHBAR_HEIGHT,
  SNV_BTN_HEIGHT,
  SNV_STOREY_BOTTOM,
  bimSeriesColors,
  bimWaterColors
};
