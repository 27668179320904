import { GetProNo } from "variables/general";

function getBaseUrl() {
  let url = process.env.REACT_APP_HTTP
  if(GetProNo()==='62') {
    url = process.env.REACT_APP_HTTP62;
  }
  return url;
}

function getDefaultBaseToken() {
  let url = "aYTXJ3BhPYWH-zMw_CgCzDR2dcfu";
  return url;
}

function getWebsocketUrl() {
  let url = process.env.REACT_APP_WS;
  return url;
}

function getDefaultWebsocketToken() {
  let url = "aYTXJ3BhPYWH-zMw_CgCzDR2dcfu";
  return url;
}

export {
  getBaseUrl,
  getDefaultBaseToken,
  getWebsocketUrl,
  getDefaultWebsocketToken
};
