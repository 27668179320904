import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

// import Icon from "@material-ui/core/Icon";

import { bimGrayColor, bimWhiteColor } from "assets/jss/bim-webs.js";
import { ConvSpaceCodeString } from "variables/general.js";

import { CBN_ASKNAVSPACE } from "variables/uescene";

import { TYPE_INFOS } from "components/Cards/TipsBar";

const STATUS_TEXTS = ["空", "报警中", "已解除"];

const iconFontSytle = {
  display: "inline-block",
  position: "absolute",
  top: "0.48rem",
  fontSize: "0.24rem",
  lineHeight: "0.32rem"
};

const styles = {
  card: {
    position: "relative",
    borderBottom: "1px solid " + bimGrayColor[1],
    boxSizing: "border-box",
    // display: "inline-block",
    height: "0.92rem",
    width: "100%",
    // width: "3.68rem",
    fontSize: "0.16rem",
    lineHeight: "0.32rem",
    color: bimWhiteColor[0]
  },
  time: {
    display: "inline-block",
    position: "absolute",
    top: "0.16rem"
  },
  status: {
    display: "inline-block",
    position: "absolute",
    top: "0.16rem",
    right: 0
  },
  title: {
    display: "inline-block",
    position: "absolute",
    top: "0.48rem",
    left: "0.32rem"
  },
  pos: {
    display: "inline-block",
    position: "absolute",
    top: "0.48rem",
    right: "0.3rem"
  },
  icon: {
    display: "inline-block",
    position: "absolute",
    top: "0.48rem",
    fontSize: "0.24rem",
    lineHeight: "0.32rem"
    // width: "0.24rem",
    // height: "0.24rem"
  }
};

const locFontSytle = {
  display: "inline-block",
  position: "absolute",
  top: "0.48rem",
  right: 0,
  fontSize: "0.24rem",
  lineHeight: "0.32rem",
  cursor: "pointer"
};

class PageComponent extends Component {
  // constructor(props) {
  //   super(props);
  //   console.log("Log constructor", props.index);
  // }
  // componentWillUnmount() {
  //   console.log("Log componentWillUnmount", this.props.index);
  // }

  handleNavigateStorey = (data, event) => {
    event.stopPropagation();
    // let ret = SearchSpaceParent(mid);
    // UeS_navigateSpace(ret);
    // window.ues_call_ui_option(CBN_ASKNAVSPACE, ret);
    window.ues_call_ui_option(CBN_ASKNAVSPACE, data.equipment_location);
  };

  render() {
    const {
      classes,
      data,
      selected,
      onClick
      // navSpace
    } = this.props;

    let info = TYPE_INFOS[data.type];
    let tmstr = data.time;
    let postr = ConvSpaceCodeString(data.pos);
    // if (index === 1) {
    //   console.log("log 1 render");
    // }

    return (
      <div
        className={classes.card}
        onClick={onClick}
        style={selected ? { background: "rgba(0,0,0,0.7)" } : null}
      >
        {/* {!!selected && (
          <div
            style={{
              position: "relative",
              display: "inline-block",
              left: "-0.2rem",
              background: "rgba(0,0,0,180)",
              height: "100%",
              width: "4rem"
            }}
          ></div>
        )} */}
        <div
          style={{
            position: "relative",
            display: "inline-block",
            left: "0.16rem",
            // background: "rgba(0,0,0,180)",
            height: "100%",
            width: "calc(100% - 0.32rem)"
          }}
        >
          <div className={classes.time}>{tmstr}</div>
          <div
            className={classes.status}
            style={1 === data.status ? { color: "#f00" } : null}
          >
            {STATUS_TEXTS[data.status]}
          </div>
          <div className={classes.pos}>{postr}</div>
          {info && (
            <span
              className={"icon font_family"}
              style={{ ...iconFontSytle, color: info.color }}
            >
              {info.icon}
            </span>
            // <Icon className={classes.icon} style={{ color: info.color }}>
            //   {info.icon}
            // </Icon>
          )}
          {info && <div className={classes.title}>{info.title}</div>}
          <span
            className={"icon font_family"}
            style={locFontSytle}
            onClick={event => this.handleNavigateStorey(data, event)}
          >
            {"\ue937"}
          </span>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PageComponent);
