import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent"

import { connect } from "react-redux";
import actions from "actions/toast"

const styles = {
    root: {
        background: "rgba(0, 0, 0, 0.9)",
        fontSize: "0.16rem",
        padding: "0.14rem 0.32rem",
        lineHeight: "1.5",
        borderRadius: "0.04rem",
        maxWidth: "4.8rem",
        minWidth: "1.6rem",
        // textAlign: "center",
        justifyContent: "center"
    },
    anchorOriginBottomCenter: {
        bottom: "1rem",
        // left:"calc(50% - 2.1rem)"

    }
};

class SnackbarComponent extends Component {

    handleClose = () => {
        this.props.toggleVisible(false);
    }

    render() {
        const {classes, toastState} = this.props;
        return (
            <Snackbar
                classes={{
                    anchorOriginBottomCenter: classes.anchorOriginBottomCenter
                }}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
                open={toastState.show}
                onClose={this.handleClose}
                autoHideDuration={1800}
                key={"bottom-center"}
            >
                <SnackbarContent
                    classes={{
                        root: classes.root
                    }}
                    message={toastState.msg || ""}
                ></SnackbarContent>

            </Snackbar>
        )
    }
}

export default withStyles(styles)(
    connect(
        ({toast: {toastState}}) => {
        return {toastState};
    },
    dispatch => {
        return {
            toggleVisible: show => dispatch(actions.toastVisible.set(show))
        }
    }
)(SnackbarComponent));