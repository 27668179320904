import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Route, HashRouter, Switch, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import navsp from "actions/navspace";

import { InitSpaceTree } from "util/webfetch.js";

import HeadBar from "components/HeadBar/HeadBar";
import SecurityFrame from "layouts/SecurityFrame.js";
import Toast from "components/Toast/Toast";
import auth from "actions/auth";

import { CBN_ASKSHOWLOADING, UeToU_setCallback } from "variables/uescene";
// import WebSocks from "util/websocks";
import {
  HEADBAR_HEIGHT,
  bimBlackColor,
  bimWhiteColor
} from "assets/jss/bim-webs.js";
import BimTheme from "assets/jss/bim-theme.js";

import { IsAuth } from "variables/general";
import Cookie from "util/cookie";

const theme = createMuiTheme(BimTheme);

const styles = {
  optpanel: {
    overflow: "hidden",
    fontSize: "0.2rem",
    lineHeight: "0.36rem",
    position: "absolute",
    top: HEADBAR_HEIGHT,
    width: "100%",
    height: `calc(100% - ${HEADBAR_HEIGHT})`
  }
};

class RouterComponent extends Component {
  // state = {};

  constructor(props) {
    super(props);

    this.state = {location: window.location.hash}

    if(!IsAuth() || (IsAuth() && Cookie.getCookie('token'))) {
      // WebSocks.start({
      //   token: Cookie.getCookie('token'),
      //   callback: this.handleSocketMsg
      // });
      InitSpaceTree( (tree, allTree) => {
        this.props.setTree(tree);
        this.props.setParkTree(allTree); //设置园区切换列表数据
      });
      let user = Cookie.getCookie('user')
      if(user) {
        this.props.setUserInfo(JSON.parse(user))
      }
    }
    

    this.loadingstate = false;
    UeToU_setCallback(CBN_ASKSHOWLOADING, this.handleAskShowLoading);

    // setInterval(() => {
    //   WebSocks.heart();
    // }, 1000 * 60 * 3);
  }

  componentDidMount() {
    window.addEventListener('hashchange', this.setLocation)
    this.addEventListener = true
    window.location = "#security";
  }

  componentWillUnmount() {
    if(this.addEventListener) {
      window.removeEventListener('hashchange', this.setLocation)
    }
  }

  setLocation = () => {
    let hash = window.location.hash
    this.setState({location:hash})
  }

  handleSocketMsg = msg => {
    // console.log("handleSocketMsg", msg);
  };

  handleAskShowLoading = param => {
    this.loadingstate = !!param.show;
    this.forceUpdate();
  };

  render() {
    const { classes } = this.props;
    
    return (
      <ThemeProvider
        theme={theme}
      >
        <HeadBar
          navs={this.props.parkTreeState?this.props.parkTreeState.parkTree:[]}
          location={this.state.location}/>
        <div
          className={classes.optpanel}
        >
          <HashRouter>
            <Switch>
              {IsAuth() &&
                <Route path="/login" component={require('views/Login/AuthLogin').default} />
              }
              <PrivateRoute path="/security" key={"security"}>
                <Route
                  path="/security"
                  component={SecurityFrame}
                />
              </PrivateRoute>
            </Switch>
          </HashRouter>
        </div>
        <Dialog fullScreen open={this.loadingstate}>
          <div
            style={{
              width: "100%",
              height: "100%",
              background: bimBlackColor[0],
              color: bimWhiteColor[0]
            }}
          >
            <div className="ldwrap">
              <div>
                <div className="k-line2 k-line12-1"></div>
                <div className="k-line2 k-line12-2"></div>
                <div className="k-line2 k-line12-3"></div>
                <div className="k-line2 k-line12-4"></div>
                <div className="k-line2 k-line12-5"></div>
              </div>
              {/* <p>努力加载中...</p> */}
            </div>
          </div>
        </Dialog>
        <Toast />
      </ThemeProvider>
    );
  }
}

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        IsAuth() && !Cookie.getCookie('token') ? (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        ) : (
          children
        )
      }
    />
  );
}

export default withStyles(styles)(
  connect(
    ({
      navspace: { spaceTreeState, parkTreeState },
      loading: { loadingState }
    }) => {
      // console.log("navSpaceState:", spaceTreeState, loadingState, roamingLayoutState);
      return {
        spaceTreeState,
        parkTreeState,
        loadingState
      };
    },
    dispatch => ({
      setTree: tree => dispatch(navsp.spaceTree.set(tree)),
      setParkTree: parktree => dispatch(navsp.parkTree.set(parktree)),
      setUserInfo: data => dispatch(auth.userInfo.set(data))
    })
  )(RouterComponent)
);
