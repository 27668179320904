import { createActions } from "redux-actions";
import { identity } from "lodash";

export default createActions({
  GET_AUTH_CHILDREN: {
    DO: [identity, (res, meta = {}) => meta],
    END: [identity, (res, meta = {}) => meta]
  },
  USER_INFO: {
    SET: data => ({ data })
  }
});
