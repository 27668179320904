import { GetProNo } from "variables/general";

function getColors() {
  let gno = GetProNo();
  switch (gno) {
    case "210":
      return {
        clBack: [
          "#111111",
          "#232323",
          "rgba(17,17,17,0.75)",
          "rgba(17,17,17,0.8)",
          '#3c3c3c',
          '#303030',
          '#656565',
          '#393939',
          '#363636',
          '#575754',
          '#626262'
        ],
        clFront: ["#EAEAEA", "rgba(234,234,234,0.75)", "#b7b7b7"],
        clActive: ["#E2CC4B", "#C5AD20", "#AE9B2B"],
        clGray: ["#A09C9C", "#515151"]
      };
    case "PEK":
      return {
        clBack: [
          "#111111",
          "rgba(32,56,100,0.6)",
          "rgba(32,56,100,0.5)",
          "rgba(32,56,100,0.4)",
          '#3c3c3c',
          '#303030',
          '#656565',
          '#393939',
          '#363636',
          '#575754',
          '#626262'
        ],
        clFront: ["#EAEAEA", "rgba(234,234,234,0.75)", "#b7b7b7"],
        clActive: ["#E2CC4B", "#C5AD20", "#AE9B2B"],
        clGray: ["#A09C9C", "#819ed5"]
      };
    default:
      return {
        clBack: [
          "#111111",
          "#232323",
          "rgba(17,17,17,0.75)",
          "rgba(17,17,17,0.5)",
          '#3c3c3c',
          '#303030',
          '#656565',
          '#393939',
          '#363636',
          '#575754',
          '#626262'
        ],
        clFront: ["#EAEAEA", "rgba(234,234,234,0.75)", "#b7b7b7"],
        clActive: ["#E2CC4B", "#C5AD20", "#AE9B2B"],
        clGray: ["#A09C9C", "#515151"]
      };
  }
}

export { getColors };

// clBack: [
//   "#EAEAEA",
//   "#D3D3D3",
//   "rgba(17,17,17,0.75)",
//   "rgba(17,17,17,0.5)"
// ],
// clFront: ["#111111", "rgba(234,234,234,0.75)"],
// clActive: ["#E2CC4B"],
// clGray: ["#A09C9C", "#515151"]
