import { xFetch } from "util/webfetch";

export const login = body => 
  xFetch("user/login", { method: "POST", body });

export const logout = () =>
  xFetch("user/logout", {
    params: {}
  });

export const getAuthChildren = rule =>
  xFetch("auth/children", {
    params: { body: JSON.stringify({ rule }) }
  });

const auth = {
  getAuthChildren
}
export default auth;
