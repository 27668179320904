import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import CustumIcon from "components/Icons/CustumIcon";
import { PROJECT_NAME } from "variables/general";
import { UeS_close, UeS_mini } from "variables/uescene";
// import logo from "assets/img/logo210.png";

import {
  HEADBAR_HEIGHT,
  bimBlackColor,
  bimWhiteColor,
  bimGrayColor
} from "assets/jss/bim-webs.js";

const ICON_WIDTH = "0.44rem";
const BLOCK_WIDTH = "0.6rem";
const BLOCK_WIDTH2 = "0.44rem";

const iconFontSytle = {
  color: "#989898",
  fontSize: "0.32rem",
  verticalAlign: "top",
  textAlign: "center",
  width: "100%",
  display: "inline-block",
  cursor: "pointer"
};

const styles = {
  bar: {
    position: "relative",
    textAlign: "center",
    width: "100%",
    height: HEADBAR_HEIGHT,
    backgroundColor: bimBlackColor[1],
    borderBottom: "1px solid " + bimGrayColor[1],
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  title: {
    position: "absolute",
    textAlign: "center",
    width: "100%",
    fontSize: "0.24rem",
    lineHeight: HEADBAR_HEIGHT,
    color: bimWhiteColor[0]
  },
  imglogo: {
    // height: "0.26rem",
    // width: "0.98rem",
    // position: "absolute",
    // left: "0.4rem",
    // top: "0.15rem"
  },
  // partName: {
  //   fontSize: "0.20rem",
  //   position: "absolute",
  //   left: "1.6rem",
  //   lineHeight: HEADBAR_HEIGHT,
  //   color: bimWhiteColor[0]
  // },

  right: {
    position: "absolute",
    right: 0,
    top: 0,
    height: HEADBAR_HEIGHT,
    lineHeight: HEADBAR_HEIGHT
  },
  blockRight: {
    // borderRight: "1px solid " + bimGrayColor[1],
    // boxSizing: "border-box",
    display: "inline-block",
    width: BLOCK_WIDTH,
    height: HEADBAR_HEIGHT
  },
  block: {
    display: "inline-block",
    width: BLOCK_WIDTH2,
    height: HEADBAR_HEIGHT
  },
  icon: {
    color: "#989898",
    width: ICON_WIDTH,
    height: ICON_WIDTH,
    padding: "0.08rem 0.42rem"
  }
};

class TopBarComponent extends Component {
  // constructor(props) {
  //   super(props);
  //   // console.log("TopBarComponent constructor");
  // }

  handleClose = () => {
    UeS_close();
    // eslint-disable-next-line no-undef
    // triggerUE4EventJSON(
    //   "onJavascriptEventJSON",
    //   JSON.stringify({
    //     UE4Func: [
    //       {
    //         funcname: "GameOver",
    //         json: ""
    //       }
    //     ]
    //   })
    // );
  };

  handleMini = () => {
    UeS_mini();
  };

  handleAccount = () => {};

  handleNotify = () => {};

  render() {
    const { classes } = this.props; //, parkname

    return (
      <div className={classes.bar}>
        {/* <img className={classes.imglogo} src={logo} alt="logo"></img> */}
        <label className={classes.title}>{PROJECT_NAME}</label>
        <div className={classes.right}>
          {/* <div className={classes.blockRight} onClick={this.handleNotify}>
            <span className={"icon font_family"} style={iconFontSytle}>
              &#xe911;
            </span>
          </div> */}
          {/* <div className={classes.blockRight} onClick={this.handleAccount}>
            <CustumIcon name={"\ue90e"} style={iconFontSytle} />
          </div> */}
          <div className={classes.block} onClick={this.handleMini}>
            <CustumIcon name={"\ue943"} style={iconFontSytle} />
          </div>
          <div className={classes.block} onClick={this.handleClose}>
            <CustumIcon name={"\ue92d"} style={iconFontSytle} />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(TopBarComponent);