import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import { bimGrayColor, bimWhiteColor } from "assets/jss/bim-webs.js";

//12, 32, 16, 16, 24
const ICON_ADD_HEIGHT = 20;
const HEIGHT_NONICARDS = [76, 12, 32, 60, 16];

const iconFontSytle = {
  position: "absolute",
  right: "0.08rem",
  // color: "#989898",
  fontSize: "0.24rem",
  // verticalAlign: "top",
  // textAlign: "center",
  // width: "100%",
  display: "inline-block"
};

const styles = {
  fill: {
    position: "absolute",
    display: "inline-block",
    textAlign: "center"
    // background: "rgba(255,255,0,0.3)",
    // color: bimWhiteColor[0]
  },
  icon: {
    position: "absolute",
    right: "0.08rem",
    width: "0.24rem",
    height: "0.24rem"
    // color: bimWhiteColor[0]
  }
};

class CardComponent extends Component {
  static defaultProps = {
    color: bimWhiteColor[0],
    bottomline: true,
    iconame: null
  };

  // constructor(props) {
  //   super(props);
  //   this.state = { selected: 0 };
  // }
  // componentDidMount() {
  // }
  // componentDidUpdate(prevProps, prevState) {
  // }

  render() {
    const {
      classes,
      width,
      iconame,
      color,
      centertext,
      bottomtext,
      rightline,
      bottomline,
      iconcolor
    } = this.props;

    let add = iconame ? ICON_ADD_HEIGHT : 0;
    let centerheight = HEIGHT_NONICARDS[2] * 0.01 + "rem";
    let bottomheight = HEIGHT_NONICARDS[4] * 0.01 + "rem";
    let dstyle = rightline
      ? {
          color,
          position: "relative",
          borderRight: "1px solid " + bimGrayColor[1],
          boxSizing: "border-box",
          display: "inline-block",
          width: width * 0.01 + "rem",
          height: (HEIGHT_NONICARDS[0] + add) * 0.01 + "rem"
        }
      : {
          color,
          position: "relative",
          display: "inline-block",
          width: width * 0.01 + "rem",
          height: (HEIGHT_NONICARDS[0] + add) * 0.01 + "rem"
        };

    let bstyle = bottomline
      ? {
          display: "inline-block",
          padding: "0.16rem 0 0.16rem 0",
          borderBottom: "1px solid " + bimGrayColor[1],
          boxSizing: "border-box"
        }
      : {
          display: "inline-block",
          padding: "0.16rem 0 0.16rem 0"
        };

    return (
      <div style={bstyle}>
        <div style={dstyle}>
          <div
            className={classes.fill}
            style={{
              top: (HEIGHT_NONICARDS[1] + add) * 0.01 + "rem",
              width: width * 0.01 + "rem",
              height: centerheight,
              fontSize: centerheight,
              lineHeight: centerheight
            }}
          >
            {centertext}
          </div>
          <div
            className={classes.fill}
            style={{
              top: (HEIGHT_NONICARDS[3] + add) * 0.01 + "rem",
              width: width * 0.01 + "rem",
              height: bottomheight,
              fontSize: bottomheight,
              lineHeight: bottomheight
            }}
          >
            {bottomtext}
          </div>
          {iconame && (
            <span
              className={"icon font_family"}
              style={{ ...iconFontSytle, color: iconcolor ? iconcolor : "" }}
            >
              {iconame}
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(CardComponent);
