import { call, put, takeLatest } from "redux-saga/effects";

import actions from "actions/auth";
import api from "api/auth";

function* getAuthChildren(action) {
  const { payload: rule } = action;

  try {
    const result = yield call(api.getAuthChildren, rule);
    yield put(actions.getAuthChildren.end(result, { rule }));
  } catch (err) {
    console.log("=====auth error: ", err);
    yield put(actions.getAuthChildren.end(err));
  }
}

/* eslint-disable */
export default function*() {
  yield takeLatest(actions.getAuthChildren.do, getAuthChildren);
}
