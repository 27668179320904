import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import Compass from "components/CustomCompass/CustomCompass";
import SpaceNaver from "components/SpaceNaver/SpaceNaver.js";
import AccessRecord from "./AccessRecord";

import CustomList from "components/CustomList/CustomList";
import InfoCard from "components/Cards/InfoCard";
import LogCard from "./LogCard";
// import InfoBar from "./InfoBar";
import CameraBar from "components/Cards/CameraBar";

import {
  LogicModuleName,
  UeS_enterMode,
  // UeS_modelState,
  UeS_navigateSpace,
  // Ues_get_mode_name,
  UeS_filterEquip,
  CBN_ASKSHOWTIP,
  UeToU_setCallback,
  UeS_spaceColor,
  UeS_modelState,
  UeS_selectEquip,
  UeS_spaceChangeInfo,
  UeS_enableCompass
} from "variables/uescene";

import {
  // AS_remToPx,
  GetSpaceTree,
  CalsSpaceMids,
  WaitEnterScene,
  //CalsCardPos
  ConvSpaceCodeString,
  ColorToUE4Rgba
} from "variables/general";

import {
  fetchSpace_storeyRooms,
  fetchSceneEquipList,
  fetchSceneStatics,
  fetchSceneBadge,
  fetchSceneLoglst,
  fetchAccessRecord,
  equipRelationLight,
  switchIls
} from "util/webfetch.js";

// import WebSocks from "util/websocks";

import {
  SCENE_RIGHT_WIDTH,
  bimBlackColor,
  bimGrayColor,
  bimWhiteColor,
  bimYellowColor
} from "assets/jss/bim-webs.js";

const ITEM_WIDTH = 192;
const TOP_HEIGHT_REM = "3.2rem";

const styles = {
  root: {
    position: "relative",
    width: "100%",
    height: "100%"
  },
  leftscene: {
    display: "inline-block",
    // background: "#00f000",
    position: "absolute",
    width: `calc(100% - ${SCENE_RIGHT_WIDTH})`,
    height: "100%"
  },
  right: {
    background: bimBlackColor[2],
    position: "absolute",
    width: SCENE_RIGHT_WIDTH,
    height: "100%",
    right: 0
  },
  top: {
    position: "relative",
    borderBottom: "1px solid " + bimGrayColor[1],
    boxSizing: "border-box",
    width: "100%",
    height: TOP_HEIGHT_REM
  },
  bottom: {
    position: "relative",
    width: "100%",
    height: `calc(100% - ${TOP_HEIGHT_REM})`
  },
  title: {
    position: "absolute",
    color: bimWhiteColor[0],
    fontSize: "0.16rem",
    lineHeight: "0.24rem",
    left: "0.16rem",
    top: "0.2rem"
  },
  // alarm: {
  //   padding: "0 0.08rem",
  //   position: "absolute",
  //   background: "#d63636",
  //   color: bimWhiteColor[0],
  //   fontSize: "0.16rem",
  //   lineHeight: "0.24rem",
  //   right: "0.16rem",
  //   top: "0.2rem"
  // },
  tabs: {
    position: "absolute",
    left: "0.16rem",
    top: "0.48rem",
    // border: "1px solid " + bimGrayColor[1],
    // boxSizing: "border-box",
    // background: bimBlackColor[0],
    width: "100%",
    height: "0.4rem",
    lineHeight: "0.4rem",
    fontSize: "0.16rem"
    // padding: "0 0.16rem"
  },
  tabtxt: {
    position: "absolute",
    top: 0,
    border: "1px solid " + bimGrayColor[1],
    // boxSizing: "border-box",
    display: "inline-block",
    width: "1.28rem",
    height: "0.4rem",
    textAlign: "center",
    background: bimBlackColor[0],
    color: bimWhiteColor[0],
    cursor: "pointer"
  },
  lst: {
    position: "absolute",
    top: "1rem",
    // left: "0.16rem",
    // width: "calc(100% - 0.32rem)",
    width: "100%",
    height: "calc(100% - 1.16rem)"
  },
  badge: {
    padding: "0 0.08rem",
    position: "absolute",
    right: 0, //"0.16rem",
    top: "-0.12rem",
    fontSize: "0.16rem",
    lineHeight: "0.24rem"
  }
};

const status_icon_colors = {
  parkNum: {
    iconame: "\ue936",
    color: bimWhiteColor[0]
  },
  indoorNum: {
    iconame: "\ue931",
    color: bimWhiteColor[0]
  },
  visitorNum: {
    iconame: "\ue92e",
    color: bimWhiteColor[0]
  },
  unverifiedNum: {
    iconame: "\ue92f",
    color: "#f00"
  },
  cameraNum: {
    iconame: "\ue935",
    color: bimWhiteColor[0]
  },
  doorControl: {
    iconame: "\ue930",
    color: bimWhiteColor[0]
  },
  detectorNum: {
    iconame: "\ue933",
    color: bimWhiteColor[0]
  },
  imageLost: {
    iconame: "\ue935",
    color: "#f00"
  },
  doorTimeout: {
    iconame: "\ue930",
    color: "#f00"
  }
  // 楼座人数: {
  //   iconame: "\ue90c",
  //   color: bimWhiteColor[0]
  // },
};

const EndItem = ({ status }) => {
  return (
    <div
      style={{
        display: "inline-block",
        color: bimWhiteColor[0],
        textAlign: "center",
        fontSize: "0.16rem",
        lineHeight: "0.24rem",
        height: "0.24rem",
        width: "100%"
      }}
    >
      {status ? "— End —" : "装载中..."}
    </div>
  );
};

class PageComponent extends Component {
  constructor(props) {
    super(props);

    this.isloading = 0;
    this.cur_pagesize = 10;

    this.modulename = null;
    this.state = {
      selected: 0,
      selitem: -1,
      statics: [],
      acessRecord: null,
      lightInfo: null,
      logdatas: [
        {
          datas: [],
          isend: false
        },
        {
          datas: [],
          isend: false
        },
        {
          datas: [],
          isend: false
        }
      ]
    };

    this.currentMid = null;
    this.space = CalsSpaceMids(props.curSpaceMids);

    UeS_enterMode(LogicModuleName.Scs_almc);
  }

  componentDidMount() {
    WaitEnterScene(LogicModuleName.Scs_almc, this.startpage);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.curSpaceMids !== this.props.curSpaceMids) {
      console.log("this.props.curSpaceMids", this.props.curSpaceMids);
      //置空已选中的摄像头
      this.setState({ sceneseldata: null });
      if (this.modulename) {
      //   this.queryData();
        let smids = this.props.curSpaceMids;
        let cur_mids = CalsSpaceMids(smids);
        //获取设备信息
        fetchSceneEquipList(
          JSON.stringify({
            module: this.modulename,
            ...cur_mids.mids
          }),
          this.handleEquipList
        );
        //获取楼层房间信息
        fetchSpace_storeyRooms(
          JSON.stringify({
            storey_mid: cur_mids.cmid
          }),
          this.handleUeStoreyRooms
        );
      }
    }
  }

  componentWillUnmount() {
    console.log("Alarm page componentWillUnmount");
    //取消订阅
    // WebSocks.subscribe();
  }

  startpage = name => {
    this.modulename = name;
    this.queryData();

    UeToU_setCallback(CBN_ASKSHOWTIP, this.handleAskShowtip);
    // UeToU_setCallback(CBN_ASKCLOSECAMERA, this.handleAskCloseCamera);

    let st = GetSpaceTree();
    if (st) {
      let park = st[0];
      UeS_navigateSpace({ park_mid: park.mid });
      // WebSocks.subscribe(this.modulename, park.mid, this.handleSubscribe);

      fetchSceneBadge(
        JSON.stringify({
          module: this.modulename,
          park_mid: park.mid
        }),
        this.handleBadge
      );
    }
    // UeS_modelState({ Style: "UnNormal" });
    UeS_enableCompass(true);
  };

  //更新3D模型空间数据
  handleUeStoreyRooms = msg => {
    if (msg && msg.data) {
      if(msg.data && msg.data.length>0){
        let spaceInfo = []
        msg.data.forEach( item => {
          let info = {
            mid: item.mid,
            name: item.name,
            number: item.number
          }
          spaceInfo.push(info)
        })
        UeS_spaceChangeInfo(spaceInfo)
      }
    }
  }

  queryAccessData = mid => {
    fetchAccessRecord(
      JSON.stringify({
        mid: mid
      }),
      this.handleAccesslst
    );
  };

  handleAccesslst = msg => {
    if (msg && msg.data) {
      this.setState({
        acessRecord: msg.data
      });
    }
  };

  handleAskCloseCamera = param => {
    this.setState({ selitem: -1 });
    this.setState({ sceneseldata: null });
  };

  handleAskShowtip = param => {
    let sceneseldata = null;
    let access_tips = null;
    for (let i = 0, l = this.cur_equiplst.length; i < l; ++i) {
      if (this.cur_equiplst[i].mid === param.mid) {
        if (
          "" === this.cur_equiplst[i].alarm_type &&
          "CR" === this.cur_equiplst[i].code
        ) {
          access_tips = {
            pos: this.cur_equiplst[i].eqp_pos,
            locx: param.x,
            locy: param.y
          };
          this.queryAccessData(this.cur_equiplst[i].mid);
          break;
        }
        sceneseldata = {
          type: this.cur_equiplst[i].alarm_type,
          pos: this.cur_equiplst[i].eqp_pos,
          status: this.cur_equiplst[i].status,
          mid: param.mid
        };
      }
    }

    //处理选中行
    let tab_selected = this.state.selected;
    let logdatas = this.state.logdatas[tab_selected];
    let row_seleced = -1;
    if (logdatas.datas) {
      logdatas.datas.forEach((row, index) => {
        if (row.mid === param.mid) {
          row_seleced = index;
        }
      });
    }

    this.currentMid = sceneseldata?sceneseldata.mid:param.mid;
    // this.getLightStatus();

    const tmp = {
      access_tips,
      selitem: row_seleced,
      sceneseldata
    };
    if (!access_tips) {
      tmp.acessRecord = null;
    }
    this.setState(tmp);
    //CalsCardPos(this.refMainCamera.current)
  };

  queryData() {
    let smids = this.props.curSpaceMids;
    let cur_mids = CalsSpaceMids(smids);

    fetchSceneEquipList(
      JSON.stringify({
        module: this.modulename,
        ...cur_mids.mids
      }),
      this.handleEquipList
    );
    fetchSceneStatics(
      JSON.stringify({
        module: this.modulename,
        ...cur_mids.mids
      }),
      this.handleStatics
    );
    // this.forceUpdate();

    this.setState({
      // selitem: -1,
      title: smids !== null ? "安防设备统计" : "人流统计"
    });
  }

  queryLogData(mids, from, sel) {
    // console.log("queryLogData");
    this.isloading = sel;
    fetchSceneLoglst(
      JSON.stringify({
        module: this.modulename,
        ...mids,
        status: sel - 1,
        from,
        size: this.cur_pagesize
      }),
      this.handleLoglst
    );
  }
  //websocket消息更新
  handleSubscribe = msg => {
    let data = msg.data;
    if (data.status) {
      data.status = parseInt(data.status);
      let logdatas = this.state.logdatas;
      let badges = this.state.badges;
      let statics = this.state.statics;
      badges[0].value++;
      logdatas[0].datas.unshift(data);
      if (1 === data.status) {
        if (!logdatas[1].isend) {
          logdatas[1].datas.unshift(data);
        }
        badges[1].value++;
        if(data.type==="SuspiciousPerson") {
          statics[3].value++;
        }
      } else if (2 === data.status) {
        if (!logdatas[2].isend) {
          logdatas[2].datas.unshift(data);
        }
        badges[2].value++;
        if(data.type==="SuspiciousPerson") {
          statics[3].value--;
        }
      }
      this.setState({ badges, logdatas });

      //更新报警设备列表
      let smids = this.props.curSpaceMids;
      let cur_mids = CalsSpaceMids(smids);
      fetchSceneEquipList(
        JSON.stringify({
          module: this.modulename,
          ...cur_mids.mids
        }),
        this.handleEquipList
      );
    }
  };

  //报警设备点亮所在空间
  handleEquipList = msg => {
    let model_state = "Normal";
    if (msg && msg.data) {
      // let mdata = msg.data;
      // console.log("handleEquipList", msg.data);

      let dlst = msg.data;
      this.cur_equiplst = dlst;
      let cur_mids = CalsSpaceMids(this.props.curSpaceMids);
      let isblock = true;
      if (cur_mids.mids.storey_mid) {
        isblock = false;
      }
      let show_alarm_icons = {
        CCTV: {
          ImageLost: {
            type: "video_alarm"
          },
          SuspiciousPerson: {
            type: "scs_suspiciouspersons"
          }
        },
        CR: {
          DoorTimeOut: {
            type: "accesscontrol_alarm"
          }
        },
        PIC: {
          PerimeterAlarm: {
            type: "scs_Perimeteralarm"
          },
          ForceDentry: {
            type: "scs_forcedentry"
          }
        },
        FAL: {
          OneTouchAlarm: {
            type: "scs_onetouchalarm"
          }
        },
        PIID: {
          IntrusionDetection: {
            type: "scs_intrusiondetection"
          }
        }
      };
      let show_icons = {
        CCTV: {
          type: "video",
          Isblock: isblock
        },
        CR: {
          type: "accesscontrol",
          Isblock: isblock
        },
        PIC: {
          type: "scs_Perimeter",
          Isblock: isblock
        }
      };
      //设备展示
      let equip_ids = [];
      let equip_states = [];
      let equip_uis = [];
      //空间展示
      let hl_space_mids = [];
      let hl_space_colors = [];
      let hl_space_color_value = ColorToUE4Rgba("#FF7D7E");

      for (let i = 0, l = dlst.length; i < l; ++i) {
        let eqp = dlst[i];
        if (show_alarm_icons[eqp.code]) {
          equip_ids[i] = eqp.mid;
          equip_states[i] = eqp.status;
          if (eqp.alarm_type !== "") {
            equip_uis[i] = !show_alarm_icons[eqp.code][eqp.alarm_type]
              ? ""
              : JSON.stringify({
                  title: eqp.title,
                  ...show_alarm_icons[eqp.code][eqp.alarm_type]
                });
            hl_space_mids.push(eqp.eqp_pos.k);
            hl_space_colors.push(hl_space_color_value);
          } else {
            let type = !show_icons[eqp.code] ? "" : show_icons[eqp.code].type
            if(type) {
              let id = equip_ids[i].split('_')[3]
              if(id) {
                type = `${type}_${id}`
              }
            }
            equip_uis[i] = !type
              ? ""
              : JSON.stringify({ title: eqp.title, type, Isblock: show_icons[eqp.code].Isblock });
          }
        }
      }

      //空间高亮 (楼层不透明)
      if (hl_space_mids.length > 0 && (this.props.curSpaceMids&&this.props.curSpaceMids.length < 2)) {
        model_state = "UnNormal";
      }
      UeS_spaceColor({
        space_ids: hl_space_mids,
        space_colors: hl_space_colors
      });

      //过滤设备显示
      UeS_filterEquip({
        equip_ids,
        equip_states,
        equip_uis,
        ...cur_mids.mids
      });
      let selitem = this.state.selitem;
      if (selitem > -1) {
        this.selChangeIcon(selitem);
      }
    }
    //设置场景模式
    UeS_modelState({ Style: model_state });
  };
  handleStatics = msg => {
    if (msg && msg.data) {
      console.log("HandleStatics", msg.data);
      let statics = msg.data.map(s => ({
        key: s.k,
        name: s.cn,
        value: s.v,
        color: bimWhiteColor[0]
      }));
      this.setState({ statics });
    }
  };
  handleBadge = msg => {
    if (msg && msg.data) {
      console.log("handleBadge", msg.data);
      let badges = msg.data.map(s => ({
        key: s.k,
        name: s.cn,
        value: s.v
        // color: bimWhiteColor[0]
      }));
      this.setState({ badges });
    }
  };
  handleLoglst = msg => {
    if (msg && msg.data) {
      console.log("HandleLoglst", msg.data);
      if (this.isloading === this.state.selected + 1) {
        this.isloading = 0;
        let sel = this.state.selected;
        let lds = this.state.logdatas[sel];
        if (msg.data.length > 0) {
          lds.datas = lds.datas.concat(msg.data);
        }
        if (msg.data.length < this.cur_pagesize) {
          lds.isend = true;
        }

        let logdatas = this.state.logdatas
        logdatas[sel] = lds
        this.setState({ logdatas })

        this.forceUpdate();
      }
    }
  };

  handleClickTab = idx => {
    this.setState({ selected: idx, selitem: -1 });
  };

  handleClickItem = idx => {
    this.setState({ sceneseldata: null });
    this.selChangeIcon(idx);

    // const curItem = this.state.logdatas[this.state.selected]["datas"];
    // this.currentMid = curItem[idx].mid;
    // let seldata = curItem[idx]
    // window.ues_call_ui_option(CBN_ASKNAVSPACE, seldata.equipment_location);
    // this.getLightStatus();
  };

  getLightStatus = () => {
    equipRelationLight(JSON.stringify({ mid: this.currentMid }), res => {
      if (res && res.data) {
        this.setState({ lightInfo: res.data });
      }
    });
  };

  handleTipsSwitchState = () => {
    const { lightGroupId, status } = this.state.lightInfo;
    if (lightGroupId) {
      const opt = status !== "on" ? "on" : "off";
      switchIls({ id: lightGroupId, opt }).then(() => {
        this.getLightStatus();
      });
    }
  };

  //选中行数据更改场景图标
  selChangeIcon = idx => {
    let eqp_data = this.state.logdatas[this.state.selected]["datas"];
    if (eqp_data[idx]) {
      let eqp_mid = eqp_data[idx].mid;
      let mids = this.space.mids;
      UeS_selectEquip({
        equipment_mid: eqp_mid,
        // equipment_state: data.status,
        ...mids
      });
    }
  };

  handleCloseInfo = () => {
    this.setState({ selitem: -1 });
  };

  loadNextPage = list => {
    if (this.isloading) {
      return;
    }

    let sel = this.state.selected;
    let lds = this.state.logdatas[sel];
    if (lds.isend) {
      return;
    }

    let cur_mids = CalsSpaceMids(this.props.curSpaceMids);
    this.queryLogData(cur_mids.mids, lds.datas.length, sel + 1);
  };

  renderStory(statics) {
    const { classes } = this.props;
    // const { title } = this.state;
    // console.log("renderStory");
    return (
      <div className={classes.top}>
        {/* <div className={classes.title}>{title}</div> */}
        <div className={classes.title}>安防设备统计</div>
        {statics.map((item, idx) => {
          let width = idx < 3 ? 130 : ITEM_WIDTH;
          let x = idx < 3 ? idx * width * 0.01 : (idx - 3) * width * 0.01;
          let y = idx > 2 ? 1.4 : 0;
          y += 0.4;
          x += 0.16;
          let rline = idx < 3 ? idx < 2 : idx - 3 < 1;
          let bline = idx > 2 ? false : true;
          let clico = status_icon_colors[item.key] || {};
          return (
            <div
              key={idx}
              style={{
                display: "inline-block",
                position: "absolute",
                left: x + "rem",
                top: y + "rem"
              }}
            >
              <InfoCard
                width={width}
                centertext={item.value}
                bottomtext={item.name}
                iconame={clico.iconame}
                color={clico.color}
                rightline={rline}
                bottomline={bline}
              />
            </div>
          );
        })}
      </div>
    );
  }

  renderStatics(statics) {
    const { classes } = this.props; //, curSpace
    const width = ITEM_WIDTH;
    // console.log("renderStatics");
    return (
      <div className={classes.top}>
        {/* <div className={classes.title}>{title}</div> */}
        <div className={classes.title}>人流统计</div>
        {statics.map((item, idx) => {
          let x = idx & 1 ? width * 0.01 : 0;
          let y = idx > 1 ? 1.4 : 0;
          y += 0.4;
          x += 0.16;
          let rline = idx & 1 ? false : true;
          let bline = idx > 1 ? false : true;
          let clico = status_icon_colors[item.key] || {};
          return (
            <div
              key={idx}
              style={{
                display: "inline-block",
                position: "absolute",
                left: x + "rem",
                top: y + "rem"
              }}
            >
              <InfoCard
                width={width}
                centertext={item.value}
                bottomtext={item.name}
                iconame={clico.iconame}
                color={clico.color}
                rightline={rline}
                bottomline={bline}
              />
            </div>
          );
        })}
      </div>
    );
  }

  closeRecord = () => {
    this.setState({
      acessRecord: null
    });
  };

  render() {
    const { classes } = this.props; //, curSpace
    const {
      selected,
      selitem,
      statics,
      badges,
      sceneseldata,
      acessRecord,
      access_tips,
      lightInfo,
      logdatas
    } = this.state;
    let curlogdatas = logdatas ? logdatas[selected] : null;
    let logdataslist = curlogdatas ? curlogdatas.datas : null;
    let seldatas = logdataslist ? logdataslist[selitem] : null;

    // let alarmtips = seldatas;
    let recordPos = "";
    if (access_tips) {
      recordPos = "位置" + ConvSpaceCodeString(access_tips.pos);
    }

    let carmeradata = {};
    if (seldatas) {
      carmeradata.devicemid = seldatas.mid;
      carmeradata.alarmtype = seldatas.type;
    }
    if (sceneseldata) {
      carmeradata.devicemid = sceneseldata.mid;
      carmeradata.alarmtype = sceneseldata.type;
      // alarmtips = sceneseldata.type ? sceneseldata : alarmtips;
    }
    return (
      <div className={classes.root}>
        <div className={classes.right}>
          {statics.length === 4 && this.renderStatics(statics)}
          {statics.length === 5 && this.renderStory(statics)}
          {statics.length !== 4 && statics.length !== 5 && (
            <div className={classes.top}></div>
          )}
          {this.modulename && (
            <div className={classes.bottom}>
              <div className={classes.title}>{"警报日志"}</div>
              {/* <div className={classes.alarm}>{"0"}</div> */}
              <div className={classes.tabs}>
                {badges &&
                  badges.map((item, idx) => {
                    let x = 1.28 * idx;
                    let style =
                      selected === idx
                        ? {
                            left: x + "rem",
                            color: bimBlackColor[0],
                            background: bimYellowColor[0]
                          }
                        : {
                            left: x + "rem",
                            color: bimWhiteColor[0],
                            background: bimBlackColor[0]
                          };
                    let bc = { background: "#d63636", color: bimWhiteColor[0] };
                    return (
                      <div
                        key={idx}
                        className={classes.tabtxt}
                        style={style}
                        onClick={() => this.handleClickTab(idx)}
                      >
                        {item.name}
                        {item.value > 0 && (
                          <div
                            className={classes.badge}
                            style={{
                              color: bc.color,
                              background: bc.background
                            }}
                          >
                            {item.value}
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
              <div className={classes.lst}>
                <CustomList
                  selected={selitem}
                  itemcomp={LogCard}
                  endcomp={EndItem}
                  loadnextcb={this.loadNextPage}
                  onClickItem={this.handleClickItem}
                  {...logdatas[selected]}
                  // itemHeight={AS_remToPx(0.92)} //"0.92rem"
                />
              </div>
            </div>
          )}
        </div>
        <div ref={this.refScene} className={classes.leftscene}>
          <SpaceNaver />
          <Compass />
          {/* {alarmtips && (
            <TipsBar data={alarmtips} onClose={this.handleCloseInfo} />
          )} */}
          {carmeradata.devicemid && (
            <CameraBar
              datas={carmeradata}
              isBut={"1"}
              sleft={"0.28"}
              lightInfo={lightInfo}
              handleTipsSwitchState={this.handleTipsSwitchState}
              handleAskCloseCamera={this.handleAskCloseCamera}
            />
          )}
          {acessRecord && (
            <AccessRecord
              recordData={acessRecord}
              recordPos={recordPos}
              onClose={this.closeRecord}
              dx={access_tips.locx}
              dy={access_tips.locy}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(({ navspace: { navSpaceState } }) => {
    return navSpaceState;
  })(PageComponent)
);
