import { fork, take, cancel, all } from "redux-saga/effects";

// Use require.context to require sagas automatically
// Ref: https://webpack.github.io/docs/context.html
const context = require.context("./", false, /\.js$/);
const keys = context
  .keys()
  .filter(item => ["./index.js", "./SagaManager.js"].indexOf(item) < 0);

const sagas = keys.map(k => context(k).default);

// export default function* root() {
//   for (let i = 0; i < keys.length; i++) {
//     const mod = context(keys[i]).default;
//     yield fork(mod);
//   }
// }

export const CANCEL_SAGAS_HMR = "CANCEL_SAGAS_HMR";

function* rootSaga() {
  const taskes = yield all(sagas.map(sg => fork(sg)));
  if (process.env.NODE_ENV !== "production") {
    yield take(CANCEL_SAGAS_HMR);
    yield cancel(taskes);
  }
}

const SagaManager = {
  start(sagaMiddleware) {
    sagaMiddleware.run(rootSaga);
  },
  cancel(store) {
    store.dispatch({
      type: CANCEL_SAGAS_HMR
    });
  }
};

export default SagaManager;
