import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
// import Popper from "@material-ui/core/Popper";

import fill from "lodash/fill";
import isEqual from "lodash/isEqual";

import CustomCamera from "components/CustomCamera/CustomCamera";

import { fetchEquipCameraDevices } from "util/webfetch.js";

const styles = {
  panel: {
    display: "inline-block",
    height: "2.48rem",
    width: "8.44rem"
  },
  main: {
    border: "0.01rem solid #515151",
    boxSizing: "border-box",
    background: "rgba(0,0,0,0.2)",
    position: "absolute",
    display: "inline-block",
    top: "0.28rem",
    left: "2.7rem",
    height: "2.48rem",
    width: "4.4rem"
  },
  cam: {
    border: "0.02rem solid #515151",
    boxSizing: "border-box",
    background: "rgba(0,0,0,0.2)",
    position: "absolute",
    display: "inline-block",
    // top: 0,
    left: "4.82rem",
    height: "0.8rem",
    width: "1.4rem"
  }
};

class CameraBarComponent extends Component {
  constructor(props) {
    super(props);
    //this.refMainCamera = React.createRef();
    // this.refCameras = [];
    // for (let i = 0; i < 3; ++i) {
    //   this.refCameras[i] = React.createRef();
    // }
    //本身是摄像头展示
    // if (0 === this.devicemids.length && 0 === props.datas.devicemid.indexOf("CCTV_")) {
    //   this.devicemids.push(props.datas.devicemid);
    // }
    this.state = { devicemids: [], idxs: [] };
    console.log(this.props.datas)
    if(this.props.datas && this.props.datas.devicemid) {
      fetchEquipCameraDevices(
        JSON.stringify({
          mids: [this.props.datas.devicemid]
        }),
        this.handleEquipCameraDevices
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevProps.datas, this.props.datas)) {
      fetchEquipCameraDevices(
        JSON.stringify({
          mids: [this.props.datas.devicemid]
        }),
        this.handleEquipCameraDevices
      );
      // this.devicemids = [this.props.datas.devicemid]
      // this.setState({ idxs: this.devicemids.map((_, i) => i) || [] });
      // console.log("CameraBarComponent componentDidUpdate", this.devicemids);
      return;
    }
  }

  handleEquipCameraDevices = msg => {
    if (msg && msg.data) {
      const { datas } = this.props;
      let devicemids = msg.data[datas.devicemid] || [];
      this.setState({ devicemids, idxs: devicemids.map((_, i) => i) || [] });
      console.log("handleEquipCameraDevices", devicemids);
    }
  };

  handleCameraSelect = idx => {
    // console.log("handleCameraSelect", idx);
    let idxs = this.state.idxs.slice(0);
    let old = idxs[idx + 1];
    idxs[idx + 1] = idxs[0];
    idxs[0] = old;
    this.setState({ idxs });
  };

  render() {
    const {
      classes,
      datas,
      sleft,
      cus_name,
      lightInfo = null,
      handleTipsSwitchState,
      handleAskCloseCamera
      // cusname
    } = this.props;
    const { devicemids, idxs } = this.state;

    //设置偏移位置
    let style_left = !sleft ? "2.7" : sleft;
    let si_main = !cus_name ? "si_main" : cus_name;
    return (
      <div>
        <div className={classes.main} style={{ left: style_left + "rem" }}>
          <CustomCamera
            name={si_main}
            title={this.props.title}
            device_mid={devicemids[idxs[0]]}
            isBut={this.props.isBut}
            lightInfo={lightInfo}
            handleTipsSwitchState={handleTipsSwitchState}
            handleAskCloseCamera={handleAskCloseCamera}
          />
        </div>
        {datas.alarmtype &&
          devicemids.length > 1 &&
          fill(Array(3), null).map((_, idx) => {
            let y = idx * 0.84 + 0.28;
            let mid = null;
            if (idx + 1 < devicemids.length) {
              mid = devicemids[idxs[idx + 1]];
            }
            return (
              <div
                key={idx}
                className={classes.cam}
                style={{ top: y + "rem" }}
                onClick={() => this.handleCameraSelect(idx)}
              >
                <CustomCamera name={"si_c" + idx} device_mid={mid} />
              </div>
            );
          })}
      </div>
    );
  }
}

export default withStyles(styles)(CameraBarComponent);
