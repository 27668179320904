import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

import FootBar from "components/FootBar/FootBar.js";
// import SpaceNaver from "components/SpaceNaver/SpaceNaver.js";
import AlarmPage from "views/Security/Alarm.js";
import AreaPage from "views/Security/Area.js";

import { MODULE_NAMES } from "variables/general";
import { FOOTBAR_HEIGHT } from "assets/jss/bim-webs.js";
import AuthContext, { AuthProvider, checkPermKey } from "contexts/AuthContext";

const this_module_name = "/" + MODULE_NAMES[0].addr;

const MODULE_TREE = [
  {
    name: "安防报警中心",
    field: "alarm",
    uq_key: "*_scs_almc_view"
  },
  {
    name: "安防重点区域",
    field: "area",
    uq_key: "*_scs_keyareas_view"
  }
];

const MODULE_PAGE = [AlarmPage, AreaPage];

const styles = {
  wrapper: {
    width: "100%",
    height: "100%"
  },
  contentPanel: {
    width: "100%",
    position: "absolute",
    height: `calc(100% - ${FOOTBAR_HEIGHT})`
  }
};

class MainComponent extends Component {
  constructor(props) {
    super(props)

    this.state = { basefield: "" };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.authChildren !== this.props.authChildren) {
      let children = MODULE_TREE;
      for(let i=0;i<children.length;i++) {
        let child = children[i]
        if(checkPermKey(this.props.authChildren, child.uq_key)) {
          this.setState({basefield: child.field})
          break;
        }
      }
    }
  }

  render() {
    const { classes, spaceTree } = this.props;
    if (!spaceTree) {
      return null;
    }

    let children = MODULE_TREE;
    // let firstchild = children[0];
    // let basefield = firstchild.field;

    return (
      <AuthProvider rule="scs">
        <div className={classes.wrapper}>
          <div className={classes.contentPanel}>
            <Switch>
              {children.map((data, key) => {
                let P = MODULE_PAGE[key];
                return (
                  <Route
                    key={key}
                    path={this_module_name + "/" + data.field}
                    render={props =>
                      <AuthContext.Consumer>
                        {checkPerm => (
                          <P
                            checkPerm={checkPerm.bind(this)}
                            {...props}
                          />
                        )}
                      </AuthContext.Consumer>
                    }
                  />
                );
              })}
              <Redirect
                from={this_module_name}
                to={this_module_name + "/" + this.state.basefield}
              />
            </Switch>
          </div>
          <FootBar path={this_module_name + "/"} itree={MODULE_TREE} />
          {/* <SpaceNaver /> */}
        </div>
      </AuthProvider>
    );
  }
}

export default withStyles(styles)(
  connect(
    ({ 
      navspace: { spaceTreeState },
      auth: { authChildren }
    }) => {
      return {
        spaceTree: spaceTreeState.spaceTree,
        authChildren: authChildren["scs"]
      };
    }
  )(MainComponent)
);
