import { call, put, takeEvery } from "redux-saga/effects";

import actions from "actions/filters";
import api from "api/filters";

function* getKeywordsearch(action) {
  const { meta, payload } = action;
  const { module } = meta;

  const { path, body, field } = payload;
  console.log("------------getKeywordsearch start", body);

  try {
    const result = yield call(api.getKeywordsearch, module, path, body);
    yield put(
      actions.getKeywordsearch.end({ field, list: result }, { module })
    );
  } catch (err) {
    console.log("------------getKeywordsearch end", err);
    yield put(actions.getKeywordsearch.end(err));
  }
}
/* eslint-disable */
export default function*() {
  yield takeEvery(actions.getKeywordsearch.do, getKeywordsearch);
}
