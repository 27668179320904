import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import SwitchButton from "../SwitchButton/StatusSwitch"
import MaxCamera from "./MaxCamera";

import {
  UeS_addVideoPlay,
  UeS_delVideoPlay,
  UeS_modifyVideoPlay
} from "variables/uescene";

import { WaitSomeCondition } from "variables/general";

import { fetchCameraRtsp } from "util/webfetch.js";

import {
  bimWhiteColor,
  bimBlackColor
} from "assets/jss/bim-webs.js";

const styles = {
  box: {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    // padding: "0.19rem",
  },
  btnLine: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    lineHeight: "1"
  },
  btn: {
    fontSize: "0.24rem",
    color: bimWhiteColor[0],
    float: "right",
    marginRight: "0.1rem",
    marginTop: "0.1rem",
    cursor: "pointer"
  },
  iconBg: {
    background: bimBlackColor[1],
    borderRadius: "0.02rem"
  },
  lightIconBg: {
    position: "absolute",
    "&:before": {
      content: "''",
      position: "absolute",
      left: "0",
      top: "0",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      background: bimBlackColor[1],
      zIndex: "-1"
    }
  },
};

class CameraComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxOpen: false,
      title: ""
    };
    this.cameraname = null;
    this.ref = React.createRef();
  }

  componentDidMount() {
    // console.log("camera componentDidMount", this.props.device_mid);
    if (this.props.device_mid) {
      fetchCameraRtsp(
        JSON.stringify({
          cctvs: [this.props.device_mid]
        }),
        this.handleCameraRtsps
      );
    }
  }

  componentWillUnmount() {
    if (this.cameraname) {
      UeS_delVideoPlay({
        video: [this.cameraname]
      });
      this.cameraname = null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.device_mid !== this.props.device_mid) {
      if (this.props.device_mid) {
        fetchCameraRtsp(
          JSON.stringify({
            cctvs: [this.props.device_mid]
          }),
          this.handleCameraRtsps
        );
      }
      return;
    }
  }

  handleCameraRtsps = msg => {
    if (msg && msg.data) {
      // console.log("handleCameraRtsps", msg.data);
      const { name, device_mid, title } = this.props;
      this.setState( {title: title ? title : name} )
      let addr = msg.data[device_mid];
      if (addr) {
        if (this.cameraname) {
          let rect = this.ref.current.getBoundingClientRect();
          UeS_modifyVideoPlay({
            video: [{ 
              name: this.cameraname,
              // title: this.state.title,
              url:addr,
              x: rect.left + 2,
              y: rect.top + 2,
              w: rect.width - 4,
              h: rect.height - 4,
              isBottom: true 
            }
            ],
          },this.ref.current);
          return;
        }
        WaitSomeCondition(
          () => !!this.ref.current,
          () => {
            this.startPlay(name, addr);
          }
        );
      }
    }
  };

  startPlay(name, addr) {
    if (this.cameraname) {
      return;
    }

    this.cameraname = name;
    let rect = this.ref.current.getBoundingClientRect();
    let url =
      addr ||
      "rtsp://admin:yanfeng123@172.18.137.37:554/h264/ch1/sub/av_stream";
    let vs = [
      {
        name: name,
        title: "",
        // title: this.state.title,
        url: url,
        isBut: this.props.isBut ? this.props.isBut : "0", //"0"  按钮都不显示 "1" 按钮都显示 "2"  按钮-关闭-显示 "3" 按钮-最大化-显示
        x: rect.left + 2,
        y: rect.top + 2,
        w: rect.width - 4,
        h: rect.height - 4,
        isBottom: true // 视频是否位于dom元素底部
      }
    ];
    //UeS_addVideoPlay({ video: vs });
    UeS_addVideoPlay({ video: vs },this.ref.current );
  }

  iconBtn = () => {
    const {classes} = this.props;
    return <span style={{borderRadius: "50%"}} className={classnames(classes.lightIconBg, "icon", "font_family")}>&#xe973;</span>
  }

  handelResize = (size) => {
    this.setState({maxOpen: size === "big" ? true : false});
  }

  handleCloseDialog = () => {
    let rect = this.ref.current.getBoundingClientRect();
    UeS_modifyVideoPlay({
      video: [{ 
          x: rect.left,
          y: rect.top,
          w: rect.width,
          h: rect.height,
          isBottom: true,
          name: this.cameraname
        }]
    },this.ref.current);
  };

  render() {
    const { maxOpen, title } = this.state;
    const {classes, lightInfo = null, handleTipsSwitchState, isBut, handleAskCloseCamera} = this.props;
    return (
      <React.Fragment>
        {!maxOpen && <div className={classes.box} ref={this.ref}>
          <div className={classes.btnLine}>
          {!!lightInfo && <div className={classes.btn}>
              <SwitchButton 
                switchOn={ lightInfo.status === "on"?true:false }
                disabled={ lightInfo.status ==="offline"?true:false }
                onChange={handleTipsSwitchState} size="small"
                icon={this.iconBtn}
                checkedIcon={this.iconBtn}
              />
            </div>}
            {/* 最大化 */}
            {(isBut === "1" || isBut === "3") && <span
              onClick={() => this.handelResize("big")}
              className={classnames(classes.btn, classes.iconBg, "icon", "font_family")}
            >&#xe967;</span>}
            {/* 关闭 */}
            {(isBut === "1" || isBut === "2") && <span
              onClick={handleAskCloseCamera}
              className={classnames(classes.btn, classes.iconBg, "icon", "font_family")}>&#xe968;</span>}
            
          </div>
        </div>}
        {(isBut === "1" || isBut === "3") && <MaxCamera
          name={this.cameraname}
          title={title}
          open={maxOpen}
          lightInfo={lightInfo}
          handleTipsSwitchState={handleTipsSwitchState}
          handleCloseCamera={this.handleCloseDialog}
          close={this.handelResize} />}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(CameraComponent);
