import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { connect } from "react-redux";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

import isEqual from "lodash/isEqual";

import StoreyBar from "./StoreyBar";

import navsp from "actions/navspace";
// import roaming from "actions/roaming";

import {
  bimYellowColor,
  SNV_BTN_HEIGHT,
  bimWhiteColor,
  bimBlackColor
} from "assets/jss/bim-webs.js";

import {
  CalsSpaceMids,
  GetSpaceTree,
  PARK_NAME,
  BUILDING_NAME
} from "variables/general";

import {
  //UeS_modelState,
  UeS_navigateSpace,
  CBN_ASKNAVSPACE,
  UeToU_setCallback,
  UeS_tool_enablemeasure,
  UeS_tool_enableroaming,
} from "variables/uescene";

const styles = {
  snbar: {
    position: "absolute",
    bottom: 0, //`calc(100% - ${FOOTBAR_HEIGHT})`,
    left: 0
  },
  button: {
    position: "absolute",
    // fontWeight: "600",
    textAlign: "center",
    left: "-0.04rem",
    width: "1.24rem",
    height: SNV_BTN_HEIGHT,
    fontSize: "0.2rem",
    fontFamily: "SourceHanSansCN-Normal",
    lineHeight: SNV_BTN_HEIGHT,
    background: bimBlackColor[0],
    color: bimWhiteColor[0]
  },
  selcolor: {
    background: bimYellowColor[0],
    color: bimBlackColor[0]
  },
  click: {
    cursor: "pointer"
  },

  mitem: {
    // textAlign: "center",
    // background: bimBlackColor[0],
    // color: bimWhiteColor[0],
    // width: "1.2rem", //NV_BTN_WIDTH +
    lineHeight: "0.44rem",
    padding: 0
  }

  // builds: {
  //   position: "absolute",
  //   top: `calc(100% - ${FOOTBAR_HEIGHT} - ${BUILD_BAR_BOTTOM}rem)`,
  //   left: 0
  // }
};

const StyledPopper = withStyles({
  paper: {
    border: "0px solid #d3d4d5"
  }
})(props => (
  <Popper
    elevation={0}
    placement="top"
    // getContentAnchorEl={null}
    // anchorOrigin={{
    //   vertical: "top",
    //   horizontal: "center"
    // }}
    // transformOrigin={{
    //   vertical: "bottom",
    //   horizontal: "center"
    // }}
    {...props}
  />
));

class SpaceNaverComponent extends Component {
  constructor(props) {
    super(props);

    // console.log("SpaceNaverComponent constructor");

    this.anchorRef = React.createRef();
    this.state = { openBuild: false };
    this.parktree = GetSpaceTree()[0];

    UeToU_setCallback(CBN_ASKNAVSPACE, this.handleAskNavSpace);
  }

  componentWillUnmount() {
    //在某个管理模块中，如果有已选中的空间，切换菜单不做清空处理，如切换模块在MainFrame中清空
    // console.log("this.props", this.props);
    // if(!this.props.curSpaceMids) {
    //   this.props.setSpace([]);
    // }
  }

  doSetSpace(space) {
    if (!isEqual(space, this.props.curSpaceMids)) {
      // const opened = this.props.roamingState.opened;
      const opened = false;
      opened && opened[2] && UeS_tool_enablemeasure({ bEnableMeasure: "false" });
      opened && opened[4] && UeS_tool_enableroaming({ bEnableRoaming: "false" });
      // isCustomStyle 空间模块带有查询条件时，切换保持透明打开状态，放到组件中自己处理
      const {customToolbar, parkStyle, buildingStyle, storeyStyle} = this.props;
      if (!customToolbar) {
        // this.props.setRoaming([false, false, false, true, false]); 
        // space.length 0:园区  1:楼座  2:楼层
        let style = "Normal";
        if (space.length === 0) {
          style = parkStyle ? parkStyle : "Normal";
        } else if (space.length === 1) {
          style = buildingStyle ? buildingStyle : "Normal";
        } else {
          style = storeyStyle ? storeyStyle : "Normal";
        }
        UeS_navigateSpace(CalsSpaceMids(space).mids, {Style: style});
      } else {
        this.props.customToolbar(CalsSpaceMids(space).mids);
      }
      this.props.setSpace(space);
    }
  }

  handleAskNavSpace = ns => {
    let st = GetSpaceTree();
    if (!st) {
      return;
    }
    let park = st[0];
    let space = [];
    // 楼座
    if (ns.building_mid && ns.building_mid.length > 0) {
      let buildidx = -1;
      let children = park.children;
      for (let i = 0, l = children.length; i < l; ++i) {
        if (children[i].mid === ns.building_mid) {
          buildidx = i;
          break;
        }
      }
      if (buildidx >= 0) {
        space[0] = buildidx;
        // 楼层
        if (ns.storey_mid && ns.storey_mid.length > 0) {
          let storeyidx = -1;
          children = park.children[buildidx].children;
          for (let i = 0, l = children.length; i < l; ++i) {
            if (children[i].mid === ns.storey_mid) {
              storeyidx = i;
              break;
            }
          }
          if (storeyidx >= 0) {
            space[1] = storeyidx;
          }
        }
      }
    }
    this.doSetSpace(space);
  };

  handleParkClick = () => {
    // console.log("handleParkClick");
    if (this.props.curSpaceMids && this.props.curSpaceMids.length > 0) {
      this.doSetSpace([]);
    }
  };

  handleBuildingClick = event => {
    this.setState({ openBuild: true });
  };

  handleBuidingMenuClose = idx => {
    if (idx >= 0) {
      this.setState({
        openBuild: false,
        openstorey: true
      });
      this.doSetSpace([idx]);
    } else {
      this.setState({ openBuild: false });
    }
  };

  handleStoreyClick = idx => {
    let sel = this.props.curSpaceMids;
    if (sel.length > 0) {
      if (2 === sel.length && sel[1] === idx) {
        return;
      }
      if (1 === sel.length && idx < 0) {
        return;
      }
      let select = [sel[0]];
      if (idx >= 0) {
        select[1] = idx;
      }
      this.doSetSpace(select);
    }
  };

  // componentDidUpdate(prevProps, prevState) {
  //   if (!isEqual(prevProps.curSpaceMids, this.props.curSpaceMids)) {
  //     let space = this.props.curSpaceMids;
  //     let { mids } = CalsSpaceMids(space);
  //     UeS_navigateSpace(mids);
  //     //UeS_modelState({ Style: "UnNormal" });
  //   }
  // }

  render() {
    const { classes, curSpaceMids } = this.props;
    const { openBuild } = this.state; //anchorEl

    let selected = curSpaceMids;
    let builds = this.parktree ? this.parktree.children : [];
    let len = selected ? selected.length : 0;
    let build = len > 0 ? builds[selected[0]] : null;
    let buildingname = len > 0 ? builds[selected[0]].name : BUILDING_NAME;
    let selstorey = len > 1 ? selected[1] : -1;
    let openstorey = !openBuild && selected && selected.length > 0;

    return (
      <div className={classes.snbar}>
        <div
          className={classNames({
            [classes.button]: true,
            [classes.selcolor]: len === 0
          })}
          style={{ bottom: 0 }}
          onClick={this.handleParkClick}
        >
          {PARK_NAME}
        </div>
        <div
          ref={this.anchorRef}
          aria-controls="menu-list-grow"
          aria-haspopup="true"
          className={classNames({
            [classes.button]: true,
            [classes.selcolor]: len > 0,
            [classes.click]: true,
          })}
          style={{ bottom: SNV_BTN_HEIGHT }}
          onClick={this.handleBuildingClick}
        >
          {buildingname}
        </div>
        <StoreyBar
          buildname={buildingname}
          storeys={build ? build.children : null}
          open={openstorey}
          select={selstorey}
          onSelect={this.handleStoreyClick}
        />
        <StyledPopper
          open={openBuild}
          anchorEl={this.anchorRef.current}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                //"center bottom"
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper
                id="menu-list-grow"
                style={{
                  background: bimBlackColor[0],
                  color: bimWhiteColor[0]
                }}
              >
                <ClickAwayListener
                  mouseEvent={"onClick"}
                  touchEvent={false}
                  onClickAway={() => this.handleBuidingMenuClose(-1)}
                >
                  <MenuList /* autoFocusItem={openBuild} */>
                    {builds.map((item, index) => (
                      <MenuItem
                        className={classes.mitem}
                        key={index}
                        onClick={() => this.handleBuidingMenuClose(index)}
                      >
                        <span
                          style={{
                            width: "1.16rem",
                            textAlign: "center"
                          }}
                        >
                          {item.name}
                        </span>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </StyledPopper>
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(
    ({ navspace: { navSpaceState: {curSpaceMids} }}) => {
      return {
        curSpaceMids
      };
    },
    dispatch => ({
      setSpace: mids => dispatch(navsp.navigationSpace.set(mids))
    })
  )(SpaceNaverComponent)
);
