import { call, put, takeEvery } from "redux-saga/effects";

import actions from "actions/navspace";
import api from "api/navspace";

function* getSpacesByStorey(action) {
  const { payload } = action;
  const storeyMid = payload;
  try {
    const result = yield call(api.getSpacesByStorey, storeyMid);
    yield put(actions.getSpacesByStorey.end(result, { storeyMid }));
  } catch (err) {
    console.log("================err: ", err);
    yield put(actions.getSpacesByStorey.end(err, { storeyMid }));
  }
}
/* eslint-disable */
export default function*() {
  yield takeEvery(actions.getSpacesByStorey.do, getSpacesByStorey);
}
